<script>
export default {
    props: {
        headers: {
            type: Object,
            required: true
        },
        contents: {
            type: Object,
            required: true
        },
        actions: {
            type: Object,
            required: true
        },
        hasActions: {
            type: Boolean
        },
        hasNextPage: {
            type: Boolean
        },
        actualPage: {
            type: Number
        },
        totalPages: {
            type: Number
        },
        totalItems: {
            type: Number
        },
    },
    methods: {
        emitClickEvent() {
            return this.$emit('loadMore');
        },
        emitActionEvent(event, data = []) {
            return this.$emit('clickAction', { eventType: event, data: data });
        },
        actionPaginationPages(page) {
            if (page != this.actualPage) {
                this.$emit('paginationPages', page);
                console.log(page);
            }
        },
        actionPaginationArrows(type) {
            if (type == 'previous' && this.actualPage > 1) {
                this.$emit('paginationArrows', 'previous');
            }
            
            if (type == 'next' && this.actualPage < this.totalPages) {
                this.$emit('paginationArrows', 'next');
            }
        }
    }
}
</script>

<template>
    <section class="bg-table">
        <table class="table">
            <tr class="table-head">
                <th v-for="item in headers" class="table-head-item">{{ item }}</th>
                <th v-if="hasActions" class="table-head-item">Ações</th>
            </tr>

            <tr v-for="(item, index) in contents" class="table-content">
                <td v-for="itemData in item" class="table-content-item"><p>{{ itemData }}</p></td>
                <td v-if="hasActions" class="table-content-item">
                    <div class="action-icons">
                        <!-- Editar -->
                        <svg @click="emitActionEvent('pencil-square', item)" v-if="actions.includes('pencil-square')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>

                        <!-- Contratos -->
                        <svg @click="emitActionEvent('document-text', item)" v-if="actions.includes('document-text')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                        </svg>

                        <!-- Downloads -->
                        <svg @click="emitActionEvent('download', item)" v-if="actions.includes('download')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>

                        <!-- Lixo -->
                        <svg @click="emitActionEvent('trash', item)" v-if="actions.includes('trash')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                        </svg>
                    </div>
                </td>
            </tr>
        </table>
    </section>

    <section @click="emitClickEvent" v-if="hasNextPage && totalPages > 1" class="bg-pagination">
        <div @click="actionPaginationArrows('previous')" :class="['pagination-get-next-page', actualPage <= 1 ? 'pagination-get-next-page-disabled' : '']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
        </div>
        
        <!-- Se o total de páginas for maior que 6, mostrar sempre a página 1 -->
        <div v-if="totalPages > 6" @click="actionPaginationPages(1)" :class="['pagination-button', actualPage == 1 ? 'pagination-active' : '']">
            1
        </div>

        <!-- Se o total de página for igual a 7 e o actual page for maior que 4, mostrar os ... -->
        <div v-if="totalPages == 7 && actualPage > 3" class="pagination-divider">
            ...
        </div>

        <!-- Se o total de página for igual a 7 e o actual page for maior que 4, mostrar os ... -->
        <div v-if="totalPages > 7 && actualPage >= 4" class="pagination-divider">
            ...
        </div>

        <!-- Se o total de páginas for até 6, listo tudo em uma linha única -->
        <div v-if="totalPages <= 6" v-for="index in totalPages" :key="index" @click="actionPaginationPages(index)" :class="['pagination-button', actualPage == index ? 'pagination-active' : '']">
            {{ index }}
        </div>

        <!-- Se o total de páginas for igual a 7 e a página atual for menor que 4, mostrar a páginação de 1 a 4 -->
        <div v-if="totalPages == 7 && actualPage < 4" v-for="index in 3" :key="index" @click="actionPaginationPages(index + 1)" :class="['pagination-button', actualPage == index + 1 ? 'pagination-active' : '']">
            {{ index + 1 }}
        </div>

        <!-- Se o total de páginas for igual a 7 e a página atual for maior ou igual que 4, mostrar a páginação de 4 a 7 -->
        <div v-if="totalPages == 7 && actualPage >= 4" v-for="index in 3" :key="index" @click="actionPaginationPages(index + 3)" :class="['pagination-button', actualPage == index + 3 ? 'pagination-active' : '']">
            {{ index + 3 }}
        </div>

        <!-- Se o total de páginas for maio que 7 e a página atual for menor que 4, mostrar a páginação de 1 a 4 -->
        <div v-if="totalPages > 7 && actualPage < 4" v-for="index in 3" :key="index" @click="actionPaginationPages(index + 1)" :class="['pagination-button', actualPage == index + 1 ? 'pagination-active' : '']">
            {{ index + 1 }}
        </div>

        <!-- Se o total de páginas for maior que 7, a página atual for maior ou igual a 7 e menor que o três vezes o valor total de páginas -->
        <div v-if="totalPages > 7 && actualPage >= 4 && actualPage < (totalPages - 2)" v-for="index in 2" :key="index" @click="actionPaginationPages(index == 1 ? actualPage : actualPage + 1)" :class="['pagination-button', index == 1 ? 'pagination-active' : '']">
            {{ index == 1 ? actualPage : actualPage + 1 }}
        </div>

        <!-- Se o total de páginas for maior que 7, a página atual for maior ou igual a 7 e menor que o três vezes o valor total de páginas -->
        <div v-if="totalPages > 7 && actualPage >= (totalPages - 2)" :key="index" @click="actionPaginationPages(totalPages - 3)" :class="['pagination-button', actualPage == (totalPages - 3) ? 'pagination-active' : '']">
            {{ totalPages - 3 }}
        </div>

        <!-- Se o total de páginas for maior que 7, a página atual for maior ou igual a 7 e menor que o três vezes o valor total de páginas -->
        <div v-if="totalPages > 7 && actualPage >= (totalPages - 2)" :key="index" @click="actionPaginationPages(totalPages - 2)" :class="['pagination-button', actualPage == (totalPages - 2) ? 'pagination-active' : '']">
            {{ totalPages - 2 }}
        </div>

        <!-- Se o total de páginas for maior que 7, a página atual for maior ou igual a 7 e menor que o três vezes o valor total de páginas -->
        <div v-if="totalPages > 7 && actualPage >= (totalPages - 2)" :key="index" @click="actionPaginationPages(totalPages - 1)" :class="['pagination-button', actualPage == (totalPages - 1) ? 'pagination-active' : '']">
            {{ totalPages - 1 }}
        </div>

        <!-- Se o total de páginas for igual a 7 e a página atual for menor que 4, mostrar os ... -->
        <div v-if="totalPages == 7 && actualPage < 4" class="pagination-divider">
            ...
        </div>

        <!-- Se o total de páginas for maior que 7, a página atual for maior ou igual a 4 e 3 numeros menor que o total de páginas, mostrar os ... -->
        <div v-if="(totalPages > 7 && actualPage < 4) || (totalPages > 7 && actualPage >= 4 && actualPage < (totalPages - 2))" class="pagination-divider">
            ...
        </div>

        <div v-if="totalPages > 6" @click="actionPaginationPages(totalPages)" :class="['pagination-button', actualPage == totalPages ? 'pagination-active' : '']">
            {{ totalPages }}
        </div>

        <div @click="actionPaginationArrows('next')" :class="['pagination-get-next-page', actualPage == totalPages ? 'pagination-get-next-page-disabled' : '']">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
        </div>
    </section>
</template>

<style scoped>
.bg-table {
    width: 100%;
    overflow-x: auto;
    border-radius: 10px;
    border: 1px solid rgb(234, 234, 234);
}

/* Estilo para ajustar a tabela */
.table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    position: relative;
}

.table-head {
    background-color: #f4f4f4;
}

.table-head-item {
    border-bottom: 1px solid rgb(234, 234, 234);
    height: 55px;
    color: #1E2245;
    font-weight: 600;
}

.table-content-item-gray {
    background-color: rgb(237, 237, 237);
}

.table-head-item, .table-content-item {
    padding: 8px 12px;
    text-align: left;
    white-space: nowrap;
}

/* .table-content-item {
    display: flex;
} */

.table-content:hover {
    background-color: rgb(250, 250, 250);
}

.table-content-item {
    padding-top: 15px;
    padding-bottom: 15px;
}

.bg-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.pagination-button,
.pagination-divider,
.pagination-get-next-page  {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    user-select:none
}

.pagination-get-next-page .icon {
    width: 18px;
    color: white;
}

.pagination-button,
.pagination-get-next-page {
    background-color: #E73E19;
    cursor: pointer;
    color: #FFF;
    font-weight: 500;
    font-size: 14px;
    margin: 0px 1px;
    transition: .2s;
}

.pagination-button:hover,
.pagination-get-next-page:hover {
    background-color: #f35a38;
    transition: .2s;
}

.pagination-active {
    background-color: transparent !important;
    border: 1px solid #E73E19 !important;
    color: black;
    cursor: default;
}

.pagination-get-next-page-disabled {
    cursor: default;
    border: 1px solid #E73E19 !important;
    background-color: transparent;
}

.pagination-get-next-page-disabled .icon {
    color: black;
}

.pagination-get-next-page-disabled:hover {
    background-color: transparent;
}

.action-icons .icon {
    width: 22px;
    cursor: pointer;
    margin-right: 5px;
    color: #E73E19;
    transition: .2s;
}

.action-icons .icon:hover {
    color: #f35a38;
    transition: .2s;
}
</style>