import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue3Toastify from 'vue3-toastify';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App);
app.use(router);
app.use(axios)
app.config.globalProperties.$axios = axios
app.use(VueAxios, axios)
app.use(PrimeVue);
app.use(Vue3Toastify, {
    "autoClose": 3000,
    "position": "bottom-right",
    "transition": "bounce",
    "dangerouslyHTMLString": true
});
app.component('QuillEditor', QuillEditor)
app.mount('#app');