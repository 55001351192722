<script>
export default {
    props: {
        title: {
            type: String,
        }
    }
}
</script>

<template>
    <section class="bg-section-title">
        <h2>{{ title }}</h2>
    </section>
</template>

<style scoped>
.bg-section-title {
    width: 100%;
}

.bg-section-title h2 {
    font-size: 18px;
    color: #1E2245;
    font-weight: 600;
}
</style>