<script>
export default {
    props: {
        inputName: {
            type: String
        },
        label: {
            type: String
        },
        options: {
            type: Object
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            modelValue: '',
        }
    },
    methods: {
        emitContent(event) {
            this.$emit('update:modelValue', event.target.value);
        },
    }
}
</script>

<template>
    <section class="bg-input">
        <label :for="inputName" class="label-input">{{ label }}</label>

        <select :name="inputName" @input="emitContent" class="input">
            <option v-for="(option, index) in options" :value="option.value" :key="option.value" :selected="option.selected === true">{{ option.name }}</option>
        </select>
    </section>
</template>

<style scoped>
.bg-input {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.bg-input .label-input {
    font-size: 14px;
    font-weight: 550;
    color: rgb(75, 75, 75);
    margin-bottom: 6px;
}

.bg-input .input {
    width: 100%;
    border: 1px solid #adadad;
    border-radius: 6px;
    padding: 12px 12px;
    font-size: 15px;
}
</style>