<script>
import Input from './../components/Input.vue';
import Button from './../components/Button.vue';
import Select from './../components/Select.vue'
import ReturnToPage from './../components/ReturnToPage.vue';
import Loader from './../components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    data() {
        return {
            loader: false,
            formRegister: {
                type: null,
                company: {
                    name_legal_representative: '',
                    name_youth_supervisor: '',
                    phone_youth_supervisor: '',
                    email_youth_supervisor: '',
                    fantasy_name_company: '',
                    social_reason_company: '',
                    cnpj_company: '',
                    code_company: '',
                    state_city_company: '',
                    address_company: '',
                    address_zipcode_company: '',
                    phone_company: '',
                    password: '',
                },
                youngapprentice: {
                    name: '',
                    email: '',
                    date_of_birth: '',
                    mother_name: '',
                    father_name: '',
                    gener: 'Masculino',
                    marital_status: 'Solteiro',
                    document_rg: '',
                    document_cpf: '',
                    phone_number: '',
                    cellphone_number: '',
                    work_card: '',
                    series_work_card: '',
                    registration_date: '',
                    education: 'Ensino Medio Incompleto',
                    has_course: 'Nao',
                    course_name: '',
                    school_name: '',
                    shift_course: 'Manha',
                    university_education: 'Nao',
                    university_education_name: '',
                    address_city: '',
                    address: '',
                    address_zipcode: '',
                    has_enlist: 'Nao',
                    has_army_reservist: 'Nao',
                    army_reservist_number: '',
                    has_informatics_knowledge: 'Nao',
                    has_disability: 'Nao',
                    password: '',
                }
            },
            generYoungApprenticeSelect: [
                {
                    name: 'Masculino',
                    value: 'Masculino',
                    selected: true
                },
                {
                    name: 'Feminino',
                    value: 'Feminino',
                    selected: false
                },
                {
                    name: 'Prefiro não dizer',
                    value: 'Indefinido',
                    selected: false
                },
            ],
            maritalStatusYoungApprenticeSelect: [
                {
                    name: 'Solteiro',
                    value: 'Solteiro',
                    selected: true
                },
                {
                    name: 'Casado',
                    value: 'Casado',
                    selected: false
                },
                {
                    name: 'Divorciado',
                    value: 'Divorciado',
                    selected: false
                },
                {
                    name: 'Viúvo',
                    value: 'Viuvo',
                    selected: false
                },
            ],
            educationYoungApprenticeSelect: [
                {
                    name: 'Ensino Médio Incompleto',
                    value: 'Ensino Medio Incompleto',
                    selected: true
                },
                {
                    name: 'Ensino Médio Completo',
                    value: 'Ensino Medio Completo',
                    selected: false
                },
            ],
            hasCourseYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            shiftCourseYoungApprenticeSelect: [
                {
                    name: 'Manhã',
                    value: 'Manha',
                    selected: true
                },
                {
                    name: 'Tarde',
                    value: 'Tarde',
                    selected: false
                },
                {
                    name: 'Noite',
                    value: 'Noite',
                    selected: false
                },
            ],
            universityEducationYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            enlistYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            armyReservistYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            hasInformaticsKnowledgeYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim, Básico',
                    value: 'Basico',
                    selected: false
                },
                {
                    name: 'Sim, Intermediário',
                    value: 'Intermediario',
                    selected: false
                },
                {
                    name: 'Sim, Avançado',
                    value: 'Avancado',
                    selected: false
                },
            ],
            hasDisabilityYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
        }
    },
    created() {
        const userToken = localStorage.getItem('UAUTHTKKEY');

        if (userToken) {
            this.$router.push('/dashboard');
        }

        const urlParams = new URLSearchParams(window.location.search);

        const registerType = urlParams.get('register_type');

        console.log(registerType);

        if (registerType) {
            if (registerType == 'empresa') {
                this.formRegister.type = 'company';
            }

            if (registerType == 'jovemaprendiz') {
                this.formRegister.type = 'youngapprentice'
            }
        }
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        register() {
            if (this.formRegister.type == 'company') {
                if (
                    !this.formRegister.company.name_legal_representative ||
                    !this.formRegister.company.name_youth_supervisor ||
                    !this.formRegister.company.email_youth_supervisor ||
                    !this.formRegister.company.fantasy_name_company ||
                    !this.formRegister.company.social_reason_company ||
                    !this.formRegister.company.cnpj_company ||
                    !this.formRegister.company.code_company ||
                    !this.formRegister.company.state_city_company ||
                    !this.formRegister.company.address_company ||
                    !this.formRegister.company.address_zipcode_company ||
                    !this.formRegister.company.phone_company ||
                    !this.formRegister.company.password
                ) {
                    return this.notify('Preencha todos os campos para concluir o cadastro empresarial!', 'error');
                }

                if (this.formRegister.company.password.length < 8) {
                    return this.notify('A sua senha deve conter no mínimo 8 caracteres!', 'error');
                }

                this.formRegister.company.type = 'company';

                this.formRegister.company.cnpj_company = this.formRegister.company.cnpj_company.replace(/\D/g, '');
            }

            if (this.formRegister.type == 'youngapprentice') {
                if (
                    !this.formRegister.youngapprentice.name ||
                    !this.formRegister.youngapprentice.email ||
                    !this.formRegister.youngapprentice.date_of_birth ||
                    !this.formRegister.youngapprentice.mother_name ||
                    !this.formRegister.youngapprentice.gener ||
                    !this.formRegister.youngapprentice.marital_status ||
                    !this.formRegister.youngapprentice.document_cpf ||
                    !this.formRegister.youngapprentice.cellphone_number ||
                    !this.formRegister.youngapprentice.work_card ||
                    !this.formRegister.youngapprentice.series_work_card ||
                    !this.formRegister.youngapprentice.registration_date ||
                    !this.formRegister.youngapprentice.education ||
                    !this.formRegister.youngapprentice.has_course ||
                    !this.formRegister.youngapprentice.shift_course ||
                    !this.formRegister.youngapprentice.university_education ||
                    !this.formRegister.youngapprentice.address_city ||
                    !this.formRegister.youngapprentice.address ||
                    !this.formRegister.youngapprentice.address_zipcode ||
                    !this.formRegister.youngapprentice.has_enlist ||
                    !this.formRegister.youngapprentice.has_army_reservist ||
                    !this.formRegister.youngapprentice.has_informatics_knowledge ||
                    !this.formRegister.youngapprentice.has_disability ||
                    !this.formRegister.youngapprentice.password
                ) {
                    return this.notify('Preencha todos os campos para concluir o cadastro de aprendiz!', 'error');
                }

                if (this.formRegister.youngapprentice.password.length < 8) {
                    return this.notify('A sua senha deve conter no mínimo 8 caracteres!', 'error');
                }
                this.formRegister.youngapprentice.type = 'youngapprentice';

                this.formRegister.youngapprentice.document_cpf = this.formRegister.youngapprentice.document_cpf.replace(/\D/g, '');
            }

            this.loader = true;

            this.$axios.post('/user', this.formRegister.type == 'youngapprentice' ? this.formRegister.youngapprentice : this.formRegister.company)
            .then(res => {
                this.notify('Usuário criado com sucesso!', 'success');

                localStorage.setItem("UAUTHTKKEY", res.data.data);
                
                setTimeout(() => {
                    this.$router.push('/dashboard');
                }, 1000);
            })
            .catch(err => {
                this.loader = false;
                this.notify('Ocorreu um erro durante o cadastro empresarial!', 'error');
            });
        }
    },
    components: { Input, Button, ReturnToPage, Select, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section id="bg-register">
        <section v-if="formRegister.type == undefined" id="select-register-type">
            <ReturnToPage placeholder="Voltar para a tela de login" redirectTo="/login" />

            <section id="header-select-register-type">
                <figure>
                    <figure>
                        <img src="@/assets/img/instituto-aprender-de-qualificacao-logo.png" alt="Instituto Aprender de Qualificação">
                    </figure>
                </figure>

                <h1>Selecione seu tipo de cadastro</h1>
            </section>

            <section id="cards-select-register-type">
                <section class="card-select-register-type" @click="formRegister.type = 'youngapprentice'">
                    <figure>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                        </svg>
                    </figure>
                    <h1>Sou Jovem Aprendiz</h1>
                    <p>Quero ter acesso a vagas para jovem aprendiz.</p>
                </section>

                <section class="card-select-register-type" @click="formRegister.type = 'company'">
                    <figure>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                        </svg>
                    </figure>
                    <h1>Sou Empresa</h1>
                    <p>Gostaria de cadastrar vagas destinada a jovens aprendizes.</p>
                </section>
            </section>
        </section>
    
        <section v-if="formRegister.type != undefined" id="register">
            <ReturnToPage placeholder="Voltar para a tela de seleção" redirectTo="/cadastro" @clickReturn="formRegister.type = null" />

            <figure>
                <img src="@/assets/img/instituto-aprender-de-qualificacao-logo.png" alt="Instituto Aprender de Qualificação">
            </figure>

            <section id="header-infos">
                <h1>{{ formRegister.type == 'company' ? 'Preencha com os dados da sua empresa' : 'Digite seus dados pessoais' }}</h1>
            </section>

            <section v-if="formRegister.type == 'company'" class="form-register">
                <Input type="text" placeholder="Nome do representante legal" label="Representante legal*" :value="formRegister.company.name_legal_representative" v-model="formRegister.company.name_legal_representative" />

                <div class="form-divider"></div>

                <Input type="email" placeholder="E-mail de acesso" label="E-mail de supervisor*" :value="formRegister.company.email_youth_supervisor" v-model="formRegister.company.email_youth_supervisor" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Nome do supervisor de aprendiz" label="Supervisor de aprendiz*" :value="formRegister.company.name_youth_supervisor" v-model="formRegister.company.name_youth_supervisor" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Telefone do supervisor de aprendiz" label="Telefone de supervisor" :value="formRegister.company.phone_youth_supervisor" v-model="formRegister.company.phone_youth_supervisor" :onlyNumbers="true" :maxLength="15" :phoneMask="true"  />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Nome fantasia da sua empresa" label="Nome fantasia*" :value="formRegister.company.fantasy_name_company" v-model="formRegister.company.fantasy_name_company" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Razão social da sua empresa" label="Razão social*" :value="formRegister.company.social_reason_company" v-model="formRegister.company.social_reason_company" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="CNPJ da sua empresa" label="CNPJ*" :value="formRegister.company.cnpj_company" v-model="formRegister.company.cnpj_company" :onlyNumbers="true" :maxLength="18" :cnpjMask="true" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Código de atividade da sua empresa" label="Código de atividade*" :value="formRegister.company.code_company" v-model="formRegister.company.code_company" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Estado e cidade da sua empresa" label="Cidade/Estado*" :value="formRegister.company.state_city_company" v-model="formRegister.company.state_city_company" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Endereço da sua empresa" label="Endereço*" :value="formRegister.company.address_company" v-model="formRegister.company.address_company" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="CEP da sua empresa" label="CEP*" :value="formRegister.company.address_zipcode_company" v-model="formRegister.company.address_zipcode_company" :onlyNumbers="true" :maxLength="12" :cepMask="true" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Contato da sua empresa" label="Telefone*" :value="formRegister.company.phone_company" v-model="formRegister.company.phone_company" :onlyNumbers="true"  :maxLength="15" :phoneMask="true"  />

                <div class="form-divider"></div>

                <Input :visibily-password="true" placeholder="●●●●●●●●●●●●" label="Senha de acesso*" :value="formRegister.company.password" v-model="formRegister.company.password" />
            </section>
            
            <section v-if="formRegister.type == 'youngapprentice'" class="form-register">
                <Input type="text" placeholder="Seu nome completo" label="Nome completo*" :value="formRegister.youngapprentice.name" v-model="formRegister.youngapprentice.name" />

                <div class="form-divider"></div>

                <Input type="email" placeholder="Seu melhor e-mail" label="E-mail*" :value="formRegister.youngapprentice.email" v-model="formRegister.youngapprentice.email" />

                <div class="form-divider"></div>

                <Input type="date" placeholder="dd/mm/yyy" label="Data de nascimento*" :value="formRegister.youngapprentice.date_of_birth" v-model="formRegister.youngapprentice.date_of_birth" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Nome completo da sua mãe" label="Nome da mãe*" :value="formRegister.youngapprentice.mother_name" v-model="formRegister.youngapprentice.mother_name" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Nome completo do seu pai" label="Nome do pai" :value="formRegister.youngapprentice.father_name" v-model="formRegister.youngapprentice.father_name" />

                <div class="form-divider"></div>

                <Select label="Sexo*" :options="generYoungApprenticeSelect" :value="formRegister.youngapprentice.gener" v-model="formRegister.youngapprentice.gener" />

                <div class="form-divider"></div>

                <Select label="Estado civil*" :options="maritalStatusYoungApprenticeSelect" :value="formRegister.youngapprentice.marital_status" v-model="formRegister.youngapprentice.marital_status" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Seu documento CPF" label="CPF*" :value="formRegister.youngapprentice.document_cpf" v-model="formRegister.youngapprentice.document_cpf" :onlyNumbers="true" :maxLength="14" :cpfMask="true" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Número de contato do responsável" label="Contato do responsável" :value="formRegister.youngapprentice.phone_number" v-model="formRegister.youngapprentice.phone_number" :onlyNumbers="true" :maxLength="15" :phoneMask="true" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Número de contato do aprendiz" label="Contato do aprendiz*" :value="formRegister.youngapprentice.cellphone_number" v-model="formRegister.youngapprentice.cellphone_number" :onlyNumbers="true" :maxLength="15" :phoneMask="true" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Número da sua carteira de trabalho" label="Nº Carteira de trabalho*" :value="formRegister.youngapprentice.work_card" v-model="formRegister.youngapprentice.work_card" :onlyNumbers="true" :maxLength="100" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Série da sua carteira de trabalho" label="Nº Série Carteira de trabalho*" :value="formRegister.youngapprentice.series_work_card" v-model="formRegister.youngapprentice.series_work_card" :onlyNumbers="true" :maxLength="100" />

                <div class="form-divider"></div>

                <Input type="date" placeholder="dd/mm/yyy" label="Data de cadastro*" :value="formRegister.youngapprentice.registration_date" v-model="formRegister.youngapprentice.registration_date" />

                <div class="form-divider"></div>

                <Input :visibily-password="true" placeholder="●●●●●●●●●●●●" label="Senha de acesso*" :value="formRegister.youngapprentice.password" v-model="formRegister.youngapprentice.password" />
            </section>

            <section v-if="formRegister.type == 'youngapprentice'" id="header-infos">
                <h1>Sua escolaridade</h1>
            </section>

            <section v-if="formRegister.type == 'youngapprentice'" class="form-register">
                <Select label="Escolaridade*" :options="educationYoungApprenticeSelect" :value="formRegister.youngapprentice.education" v-model="formRegister.youngapprentice.education" />

                <div class="form-divider"></div>

                <Select label="Fez algum curso*?" :options="hasCourseYoungApprenticeSelect" :value="formRegister.youngapprentice.has_course" v-model="formRegister.youngapprentice.has_course" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Digite o nome do curso que você fez" label="Nome do curso" :value="formRegister.youngapprentice.course_name" v-model="formRegister.youngapprentice.course_name" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Digite o nome da sua escola" label="Escola" :value="formRegister.youngapprentice.school_name" v-model="formRegister.youngapprentice.school_name" />

                <div class="form-divider"></div>

                <Select label="Turno disponível para trabalhar*" :options="shiftCourseYoungApprenticeSelect" :value="formRegister.youngapprentice.shift_course" v-model="formRegister.youngapprentice.shift_course" />

                <div class="form-divider"></div>

                <Select label="Ensino Superior*" :options="universityEducationYoungApprenticeSelect" :value="formRegister.youngapprentice.university_education" v-model="formRegister.youngapprentice.university_education" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Digite o nome do ensino que você fez" label="Nome do ensino" :value="formRegister.youngapprentice.university_education_name" v-model="formRegister.youngapprentice.university_education_name" />
            </section>

            <section v-if="formRegister.type == 'youngapprentice'" id="header-infos">
                <h1>Informações adicionais</h1>
            </section>

            <section v-if="formRegister.type == 'youngapprentice'" class="form-register">
                <Input type="text" placeholder="Digite sua cidade" label="Cidade*" :value="formRegister.youngapprentice.address_city" v-model="formRegister.youngapprentice.address_city" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Digite seu endereço" label="Endereço*" :value="formRegister.youngapprentice.address" v-model="formRegister.youngapprentice.address" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Digite o CEP do seu endereço" label="CEP*" :value="formRegister.youngapprentice.address_zipcode" v-model="formRegister.youngapprentice.address_zipcode" :onlyNumbers="true" :maxLength="12" :cepMask="true" />

                <div class="form-divider"></div>

                <Select label="Já se alistou no serviço militar?*" :options="enlistYoungApprenticeSelect" :value="formRegister.youngapprentice.has_enlist" v-model="formRegister.youngapprentice.has_enlist" />

                <div class="form-divider"></div>

                <Select label="Possui reservista?*" :options="armyReservistYoungApprenticeSelect" :value="formRegister.youngapprentice.has_army_reservist" v-model="formRegister.youngapprentice.has_army_reservist" />

                <div class="form-divider"></div>

                <Input type="text" placeholder="Número da sua carteira de reservista" label="N° Cart. Reservista" :value="formRegister.youngapprentice.army_reservist_number" v-model="formRegister.youngapprentice.army_reservist_number" />

                <div class="form-divider"></div>

                <Select label="Tem conhecimento de informática?*" :options="hasInformaticsKnowledgeYoungApprenticeSelect" :value="formRegister.youngapprentice.has_informatics_knowledge" v-model="formRegister.youngapprentice.has_informatics_knowledge" />

                <div class="form-divider"></div>

                <Select label="Portador de necessidades especiais?*" :options="hasDisabilityYoungApprenticeSelect" :value="formRegister.youngapprentice.has_disability" v-model="formRegister.youngapprentice.has_disability" />
            </section>

            <section id="actions-form-register">
                <Button type="primary" placeholder="Finalizar cadastro" @buttonPressed="register" />
            </section>
        </section>
    </section>
</template>7

<style scoped>
#bg-register {
    width: 100%;
    min-height: 100vh;
    background-color: #F8F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto !important;
}

#bg-register #register,
#bg-register #select-register-type {
    width: 650px;
    max-width: 98%;
    height: auto;
    padding: 30px 40px;
    background-color: white;
    border: 1px solid rgb(234, 234, 234);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 50px 0px 50px 0px;
}

#bg-register #select-register-type #header-select-register-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#bg-register #select-register-type #header-select-register-type figure img {
    width: 130px;
    margin-top: 20px;
}

#bg-register #select-register-type #header-select-register-type h1 {
    font-weight: 650;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #1E2245;
}

#bg-register #select-register-type #cards-select-register-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

#bg-register #select-register-type #cards-select-register-type .card-select-register-type {
    width: 250px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid rgb(196, 196, 196);
    padding: 15px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: .2s;
}

#bg-register #select-register-type #cards-select-register-type .card-select-register-type:hover {
    border: 1px solid #E73E19;
    transition: .2s;
}

#bg-register #select-register-type #cards-select-register-type .card-select-register-type figure .icon {
    width: 50px;
    color: #e73f197c;
    transition: .2s;
}

#bg-register #select-register-type #cards-select-register-type .card-select-register-type:hover .icon {
    color: #E73E19;
    transition: .2s;
}

#bg-register #select-register-type #cards-select-register-type .card-select-register-type h1 {
    font-weight: 650;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #1E2245;
}

#bg-register #select-register-type #cards-select-register-type .card-select-register-type p {
    font-size: 14px;
    font-weight: 450;
    color: rgb(63, 63, 63);
}

#bg-register #register figure img {
    width: 130px;
    margin-top: 20px;
}

#bg-register #register #header-infos {
    width: 100%;
    text-align: center;
    margin: 25px 0px 30px 0px;
}

#bg-register #register #header-infos h1 {
    font-weight: 550;
    font-size: 18px;
    color: #1E2245;
}

#bg-register #register .form-register {
    width: 100%;
}

#bg-register #register #recover-password-button {
    margin-top: 15px;
    width: 100%;
}

#bg-register #register #recover-password-button p {
    color: #E73E19;
    font-weight: 550;
    cursor: pointer;
    width: 200px;
}

#bg-register #register #recover-password-button p:hover {
    color: #f35a38;
    transition: 0.2s;
}

#bg-register #register #actions-form-register {
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    #bg-register #select-register-type #cards-select-register-type {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #bg-register #select-register-type #cards-select-register-type .card-select-register-type {
        width: 100%;
        margin-bottom: 30px;
    }
}
</style>