<script>
export default {
    props: {
        title: {
            type: String
        },
        description: {
            type: String
        }
    }
}
</script>

<template>
    <section class="card">
        <div class="icon-card">
            <slot></slot>
        </div>

        <div class="content-card">
            <h1>{{ title }}</h1>
            <p>{{ description }}</p>
        </div>
    </section>
</template>

<style scoped>
.card {
    width: 100%;
    min-height: 120px;
    background-color: #FFF;
    border: 1px solid rgb(234, 234, 234);
    border-radius: 15px;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-card {
    text-align: left;
    width: 100%;
    padding-left: 20px;
}

.content-card h1 {
    font-size: 18px;
    color: #1E2245;
}

.content-card p {
    font-size: 24px;
    color: #E73E19;
}
</style>