import LoginView from '@/views/LoginView.vue'
import RecoverPasswordView from '@/views/RecoverPasswordView.vue';
import RegisterView from '@/views/RegisterView.vue';
import DashboardView from '@/views/DashboardView.vue';
import InitialDashboardView from '@/views/DashboardViews/InitialDashboardView.vue';
import ApprenticesView from '@/views/DashboardViews/ApprenticesView.vue';
import CompaniesView from '@/views/DashboardViews/CompaniesView.vue';
import CbosView from './../views/DashboardViews/CbosView.vue';
import ProfileView from './../views/DashboardViews/ProfileView.vue';
import ContractsView from './../views/DashboardViews/ContractsView.vue';
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/:pathMatch(.*)*', redirect: '/' },
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/cadastro',
    name: 'Cadastro',
    component: RegisterView
  },
  {
    path: '/recuperar-senha/:token',
    name: 'Recuperar senha',
    component: RecoverPasswordView
  },
  {
    path: '/dashboard',
    component: DashboardView,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: InitialDashboardView
      },
      {
        path: '/dashboard/aprendizes',
        name: 'Aprendizes',
        component: ApprenticesView
      },
      {
        path: '/dashboard/empresas',
        name: 'Empresas',
        component: CompaniesView
      },
      {
        path: '/dashboard/contratos',
        name: 'Contratos',
        component: ContractsView
      },
      {
        path: '/dashboard/cbos',
        name: 'CBOS',
        component: CbosView
      },
      {
        path: '/meu-perfil',
        name: 'Meu Perfil',
        component: ProfileView
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name} - IAQ` || 'Instituto Aprender de Qualificação';
  next();
});

export default router
