<script>
export default {
    props: {
        title: {
            type: String,
        }
    },
    methods: {
        clickButton(boolean) {
            return this.$emit('clickButton', boolean)
        }
    },
}
</script>

<template>
    <section class="bg-delete-modal">
        <div class="modal">
            <header>
                <h3>{{ title }}</h3>
            </header>

            <article>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>

                <h3>Atenção!</h3>

                <p>Você tem certeza que deseja deletar esse conteúdo permanentemente?</p>
                <p>Essa ação é irreversível e permanente!</p>
            </article>

            <footer>
                <button class="cancel-button" @click="clickButton(false)">
                    Cancelar
                </button>

                <button class="confirm-button" @click="clickButton(true)">
                    Confirmar
                </button>
            </footer>
        </div>
    </section>
</template>

<style scoped>
.bg-delete-modal {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.277);
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    margin: 60px 0px 30px 0px;
    background-color: white;
    width: 600px;
    max-width: 98%;
    border-radius: 16px;
}

.modal .icon {
    width: 60px;
    color: #E73E19;
}


.modal header {
    width: 100%;
    border-bottom: 1px solid rgb(222, 222, 222);
    padding: 18px 20px;
}

.modal header h3 {
    font-weight: 550;
    color: #1E2245;
}

.modal article {
    padding: 40px 16px;
    text-align: center;
}

.modal article h3 {
    margin: 16px 0px;
    font-size: 20px;
    color: #E73E19;
}

.modal article p {
    margin-bottom: 10px;
    color: #1E2245;
    font-weight: 450;
}

.modal footer {
    width: 100%;
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid rgb(222, 222, 222);
}

.modal footer button {
    padding: 12px 20px;
    margin-left: 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    font-size: 16px;
    cursor: pointer;
}

.confirm-button {
    background-color: #E73E19;
    border: 1px solid #E73E19;
    color: white;
}

.confirm-button:hover {
    background-color: #FFF;
    border: 1px solid #E73E19;
    color: #1E2245;
}

.cancel-button {
    border: 1px solid rgb(221, 221, 221) !important;
}

.cancel-button:hover {
    background-color: rgb(221, 221, 221) !important;
}
</style>