<script>
import Input from './../components/Input.vue';
import Button from './../components/Button.vue';

export default {
    data() {
        return {
            formRecoverPassword: {
                password: '',
                confirm_password: ''
            },
        }
    },
    created() {
        const userToken = localStorage.getItem('UAUTHTKKEY');

        if (userToken) {
            this.$router.push('/dashboard');
        }
    },
    methods: {
        login() {
            this.$router.push('/dashboard');
        },
        recoverPassword() {
            this.$router.push('/cadastro');
        }
    },
    components: { Input, Button }
}
</script>

<template>
    <section id="bg-login">
        <section id="login">
            <figure>
                <img src="@/assets/img/instituto-aprender-de-qualificacao-logo.png" alt="Instituto Aprender de Qualificação">
            </figure>

            <section id="header-infos">
                <h1>Defina sua nova senha</h1>
            </section>

            <section id="form-login">
                <Input :visibily-password="true" placeholder="●●●●●●●●●●●●" label="Senha" :value="formRecoverPassword.password" v-model="formRecoverPassword.password" />
                <div class="form-divider"></div>
                <Input :visibily-password="true" placeholder="●●●●●●●●●●●●" label="Confirmação de senha" :value="formRecoverPassword.confirm_password" v-model="formRecoverPassword.confirm_password" />
            </section>

            <section id="actions-form-login">
                <Button type="primary" placeholder="Definir senha" />
            </section>
        </section>
    </section>
</template>7

<style scoped>
#bg-login {
    width: 100%;
    height: 100vh;
    background-color: #F8F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
}

#bg-login #login {
    width: 650px;
    max-width: 98%;
    height: auto;
    padding: 30px 40px;
    background-color: white;
    border: 1px solid rgb(234, 234, 234);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#bg-login #login figure img {
    width: 130px;
}

#bg-login #login #header-infos {
    width: 100%;
    text-align: center;
    margin: 20px 0px;
}

#bg-login #login #header-infos h1 {
    font-weight: 550;
    font-size: 18px;
    color: #1E2245;
}

#bg-login #login #form-login {
    width: 100%;
}

#bg-login #login #recover-password-button {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#bg-login #login #recover-password-button p {
    color: #E73E19;
    font-weight: 500;
    cursor: pointer;
}

#bg-login #login #recover-password-button p:hover {
    color: #f35a38;
    transition: 0.3s;
}

#bg-login #login #actions-form-login {
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    #bg-login #login #recover-password-button {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    #bg-login #login #recover-password-button p {
        font-size: 16px;
        margin-top: 5px;
    }
}
</style>