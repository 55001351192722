<template>
    <section class="bg-loader">
        <div class="loader"></div>
    </section>
</template>

<style scoped>
.bg-loader {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 22;
    top: 0;
    left: 0;
    background-color: rgba(83, 83, 83, 0.506);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
    border-top: 2px solid #fb6a4a;
    border-right: 2px solid #fb6a4a;
    border-bottom: 2px solid #fb6a4a;
    border-radius: 100%;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>