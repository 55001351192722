<script>
import Head from './../../components/Head.vue'
import Card from '@/components/Card.vue';
import Loader from './../../components/Loader.vue'
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    props: [ 'userData' ],
    data() {
        return {
            loader: true,
            dashInfos: undefined
        }
    },
    created() {
        if (this.userData.role == 'youngapprentice' || this.userData.role == 'company') {
            this.$router.push('/meu-perfil');
            return;
        }

        this.$axios.get('dash_infos')
        .then(res => {
            console.log(res)
            this.dashInfos = res.data.data;

            this.loader = false;
        })
        .catch(err => {
            this.notify('Aconteceu um erro durante a busca por dados da Dashboard!', 'error');
        });
    },
    methods: {
        notify(text, type) {
        toast(text, {
            type:
            type == "info"
                ? "info"
                : type == "warning"
                ? "warning"
                : type == "error"
                ? "error"
                : type == "success"
                ? "success"
                : "default",
        });
        },
    },
    components: { Head, Card, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section v-if="!loader">
        <Head title="Dashboard"></Head>

        <section class="bg-dash-cards">
            <Card @click="this.$router.push('/dashboard/aprendizes')" class="card" title="Aprendizes" :description="dashInfos.young_apprendities">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="i-icon-card">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                </svg>
            </Card>

            <Card @click="this.$router.push('/dashboard/empresas')" class="card" title="Empresas" :description="dashInfos.companies">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="i-icon-card">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                </svg>
            </Card>

            <Card @click="this.$router.push('/dashboard/cbos')" class="card" title="CBOS" :description="dashInfos.cbos">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="i-icon-card">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                </svg>
            </Card>

            <Card @click="this.$router.push('/dashboard/contratos')" class="card" title="Contratos" :description="dashInfos.contracts">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="i-icon-card">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                </svg>
            </Card>
        </section>
    </section>
</template>

<style scoped>
.bg-dash-cards {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
}

.i-icon-card {
    width: 55px;
    color: #e73f197c;
    transition: .2s
}

.card {
    width: 280px;
    margin-bottom: 20px;
    cursor: pointer;
}

.card:hover .i-icon-card{
    color: #E73E19;
    transition: .2s;
}

@media screen and (max-width: 690px) {
    .bg-dash-cards {
        justify-content: center;
        flex-direction: column;
    }

    .card {
        width: 100%;
    }
}
</style>