<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Loader from './../components/Loader.vue'

export default {
    data() {
        return {
            loader: true,
            userData: null,
        }
    },
    created() {
        this.$axios.get('/user')
        .then(res => {
            this.userData = res.data;
            this.loader = false;
        })
        .catch(err => {
            this.notify('Erro ao buscar usuário!', 'error');

            this.disconnect();
        });
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        openLateralNavBar() {
            const lateralNavBar = document.getElementById("lateral-nav-bar");
            const closeLateralNavBar = document.getElementById("close-lateral-nav-bar");
            const lateralNavBarDarkBg = document.getElementById("lateral-nav-bar-dark-bg");

            lateralNavBarDarkBg.style.display = "flex";
            lateralNavBar.style.left = "0px";

            setTimeout(() => {
                closeLateralNavBar.style.display = "flex";
            }, 100);
        },
        closeLateralNavBar() {
            const lateralNavBar = document.getElementById("lateral-nav-bar");
            const closeLateralNavBar = document.getElementById("close-lateral-nav-bar");
            const lateralNavBarDarkBg = document.getElementById("lateral-nav-bar-dark-bg");

            lateralNavBar.style.left = "-300px";
            closeLateralNavBar.style.display = "none";
            lateralNavBarDarkBg.style.display = "none";
        },
        redirectToPage(page) {
            this.$router.push(page);
        },
        disconnect() {
            localStorage.removeItem('UAUTHTKKEY');

            this.$router.push("/");
        }
    }
}
</script>

<template>
    <Loader v-if="loader" />

    <section v-if="!loader" class="bg-dashboard">
        <section id="lateral-nav-bar" class="lateral-nav-bar">
            <figure>
                <img src="@/assets/img/instituto-aprender-de-qualificacao-logo.png" alt="IAQ - Instituto aprender de qualificação">
            </figure>

            <nav class="lateral-nav-bar-items">
                <div v-if="userData.role == 'admin' || userData.role == 'superadmin'" @click="redirectToPage('/dashboard'); closeLateralNavBar()" :class="[this.$route.path == '/dashboard' ? 'active' : '', 'lateral-nav-bar-item']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="[this.$route.path == '/dashboard' ? 'orange' : '', 'icon']">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>

                    <p>Dashboard</p>
                </div>

                <div v-if="userData.role == 'youngapprentice' || userData.role == 'company'" @click="redirectToPage('/meu-perfil'); closeLateralNavBar()" :class="[this.$route.path == '/meu-perfil' ? 'active' : '', 'lateral-nav-bar-item']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="[this.$route.path == '/meu-perfil' ? 'orange' : '', 'icon']">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>

                    <p>Meu perfil</p>
                </div>

                <div v-if="userData.role == 'admin' || userData.role == 'superadmin'" @click="redirectToPage('/dashboard/aprendizes'); closeLateralNavBar()" :class="[this.$route.path == '/dashboard/aprendizes' ? 'active' : '', 'lateral-nav-bar-item']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="[this.$route.path == '/dashboard/aprendizes' ? 'orange' : '/dashboard/aprendizes', 'icon']">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>

                    <p>Aprendizes</p>
                </div>

                <div v-if="userData.role == 'admin' || userData.role == 'superadmin'" @click="redirectToPage('/dashboard/empresas'); closeLateralNavBar()" :class="[this.$route.path == '/dashboard/empresas' ? 'active' : '', 'lateral-nav-bar-item']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="[this.$route.path == '/dashboard/empresas' ? 'orange' : '', 'icon']">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z" />
                    </svg>

                    <p>Empresas</p>
                </div>

                <div v-if="userData.role == 'admin' || userData.role == 'superadmin'" @click="redirectToPage('/dashboard/contratos'); closeLateralNavBar()" :class="[this.$route.path == '/dashboard/contratos' ? 'active' : '', 'lateral-nav-bar-item']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="[this.$route.path == '/dashboard/contratos' ? 'orange' : '', 'icon']">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                    </svg>

                    <p>Contratos</p>
                </div>

                <div v-if="userData.role == 'admin' || userData.role == 'superadmin'" @click="redirectToPage('/dashboard/cbos'); closeLateralNavBar()" :class="[this.$route.path == '/dashboard/cbos' ? 'active' : '', 'lateral-nav-bar-item']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" :class="[this.$route.path == '/dashboard/cbos' ? 'orange' : '', 'icon']">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
                    </svg>

                    <p>CBOS</p>
                </div>

                <div @click="disconnect" class="lateral-nav-bar-item">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75" />
                    </svg>
                    <p>Desconectar</p>
                </div>
            </nav>
        </section>

        <section @click="closeLateralNavBar" id="lateral-nav-bar-dark-bg" class="lateral-nav-bar-dark-bg"></section>

        <section class="central-content">
            <div @click="openLateralNavBar" class="open-lateral-nav-bar">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </div>

            <div @click="closeLateralNavBar" id="close-lateral-nav-bar" class="close-lateral-nav-bar">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
            </div>

            <div class="router">
                <router-view :userData="userData"></router-view>
            </div>

            <footer>
                <p>Todos os direitos reservados © Instituto Aprender de Qualificação</p>

                <div class="links">
                    <a href="https://iaq.org.br/contato/" target="_blank">Fale conosco</a>
                        <div class="divider">|</div>
                    <a href="https://iaq.org.br/" target="_blank">Quero ajuda</a>
                </div>
            </footer>
        </section>
    </section>
</template>

<style scoped>
.bg-dashboard {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: row;
    background-color: #F8F9FA;
    overflow: hidden;
}

.lateral-nav-bar {
    width: 300px;
    height: 100vh;
    background-color: white;
    border: 1px solid rgb(234, 234, 234);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0px 20px;
    transition: .2s;
}

.lateral-nav-bar figure {
    width: 100%;
    border-bottom: 1px solid rgb(234, 234, 234);
    text-align: center;
    padding: 20px 0px;
}

.lateral-nav-bar figure img {
    width: 130px;
}

.lateral-nav-bar .lateral-nav-bar-items {
    width: 100%;
    margin-top: 20px;
}

.lateral-nav-bar .lateral-nav-bar-items .lateral-nav-bar-item {
    width: 100%;
    border-radius: 5px;
    padding: 14px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.active p {
    color: #1E2245 !important;
}

.active:hover p {
    color: #424981 !important;
}

.lateral-nav-bar .lateral-nav-bar-items .lateral-nav-bar-item p {
    font-size: 18px;
    padding-left: 10px;
    font-weight: 500;
    color: #5d5d61;
}

.lateral-nav-bar .lateral-nav-bar-items .lateral-nav-bar-item .icon {
    width: 20px;
    transition: .2s;
    color: #5d5d61;
}

.lateral-nav-bar .lateral-nav-bar-items .lateral-nav-bar-item:hover p {
    transition: .2s;
    color: #4a4a63;
}

.lateral-nav-bar .lateral-nav-bar-items .lateral-nav-bar-item:hover .icon {
    color: #dc6146 !important;
    transition: .2s;
}

.open-lateral-nav-bar {
    display: none;
}

.close-lateral-nav-bar {
    display: none;
}

.lateral-nav-bar-dark-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.224);
    position: fixed;
    z-index: 8;
    display: none;
}

.orange {
    color: #E73E19 !important;
}

.central-content {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    overflow-x: hidden;
}

.central-content .router {
    padding: 50px 50px 0px 50px !important;
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.central-content footer {
    margin-top: 30px;
    width: 100%;
    min-height: 120px;
    background-color: #FFF;
    border-top: 1px solid rgb(234, 234, 234);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 10px;
}

.central-content footer p {
    color: #1E2245;
    font-size: 14px;
    text-align: center;
}

.central-content footer .links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.central-content footer .links .divider {
    margin: 0px 10px;
    color: #1E2245;
}

.central-content footer .links a {
    color: #E73E19;
    transition: .3s;
    font-size: 14px;
}

.central-content footer .links a:hover {
    color: #dc6146;
    transition: .3s;
}

@media screen and (max-width: 920px) {
    .lateral-nav-bar {
        position: absolute;
        left: -300px;
        top: 0px;
        z-index: 10;
    }

    .central-content .router {
        padding: 50px 20px 0px 20px !important;
        margin-top: 20px;
    }

    .open-lateral-nav-bar {
        position: absolute;
        left: 15px;
        top: 10px;
        cursor: pointer;
        z-index: 9;
        display: flex;
    }

    .open-lateral-nav-bar .icon {
        width: 30px;
        color: #1E2245;
    }

    
    .close-lateral-nav-bar {
        position: absolute;
        left: 15px;
        top: 10px;
        cursor: pointer;
        z-index: 11;
        display: none;
    }

    .close-lateral-nav-bar .icon {
        width: 25px;
        color: #1E2245;
    }
}
</style>