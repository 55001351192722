<script>
import Input from './../components/Input.vue';
import Button from './../components/Button.vue';
import Loader from './../components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    data() {
        return {
            formLogin: {
                username: '',
                password: ''
            },
            view: 'login',
            loader: true,
        }
    },
    created() {
        const userToken = localStorage.getItem('UAUTHTKKEY');

        if (userToken) {
            return this.$router.push('/dashboard');
        }

        this.loader = false;
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        login() {
            if (!this.formLogin.username || !this.formLogin.password) {
                return this.notify('Preencha todos os dados para fazer login!', 'error');
            }

            this.loader = true;

            this.$axios.post('user/login', {
                username: this.formLogin.username,
                password: this.formLogin.password
            })
            .then(res => {
                this.notify('Usuário autenticado com sucesso!', 'success');

                localStorage.setItem("UAUTHTKKEY", res.data.data.token);

                setTimeout(() => {
                    this.$router.push('/dashboard');
                }, 1000);
            })
            .catch(err => {
                this.loader = false;
                return this.notify('Usuário ou senha incorretos!', 'error');
            })
        },
        registerMe() {
            this.$router.push('/cadastro');
        }
    },
    components: { Input, Button, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section id="bg-login">
        <section id="login">
            <figure>
                <img src="@/assets/img/instituto-aprender-de-qualificacao-logo.png" alt="Instituto Aprender de Qualificação">
            </figure>

            <section id="header-infos">
                <h1>{{ view == 'login' ? 'Acesse sua conta' : 'Recupere sua senha' }}</h1>
            </section>

            <section id="form-login">
                <Input type="email" placeholder="Digite seu E-mail ou seu CPF!" label="CPF ou E-mail" :value="formLogin.username" v-model="formLogin.username" />
                <div v-if="view == 'login'" class="form-divider"></div>
                <Input v-if="view == 'login'" :visibily-password="true" placeholder="●●●●●●●●●●●●" label="Sua senha" :value="formLogin.password" v-model="formLogin.password" />
            </section>

            <section id="recover-password-button">
                <p v-if="view == 'login'" @click="registerMe">Gostaria de me cadastrar</p>
                <p v-if="view == 'login'" @click="view = 'recuperarsenha'">Esqueci a minha senha</p>
                <p v-if="view != 'login'" @click="view = 'login'">Lembrou a sua senha? Clique aqui</p>
            </section>

            <section id="actions-form-login">
                <Button v-if="view == 'login'" type="primary" placeholder="Acessar" @buttonPressed="login" />
                <Button v-if="view == 'recuperarsenha'" type="primary" placeholder="Recuperar senha" />
            </section>
        </section>
    </section>
</template>7

<style scoped>
#bg-login {
    width: 100%;
    height: 100vh;
    background-color: #F8F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
}

#bg-login #login {
    width: 650px;
    max-width: 98%;
    height: auto;
    padding: 30px 40px;
    background-color: white;
    border: 1px solid rgb(234, 234, 234);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#bg-login #login figure img {
    width: 130px;
}

#bg-login #login #header-infos {
    width: 100%;
    text-align: center;
    margin: 20px 0px;
}

#bg-login #login #header-infos h1 {
    font-weight: 550;
    font-size: 18px;
    color: #1E2245;
}

#bg-login #login #form-login {
    width: 100%;
}

#bg-login #login #recover-password-button {
    margin-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#bg-login #login #recover-password-button p {
    color: #E73E19;
    font-weight: 500;
    cursor: pointer;
}

#bg-login #login #recover-password-button p:hover {
    color: #f35a38;
    transition: 0.3s;
}

#bg-login #login #actions-form-login {
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    #bg-login #login #recover-password-button {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    #bg-login #login #recover-password-button p {
        font-size: 16px;
        margin-top: 5px;
    }
}
</style>