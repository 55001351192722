<script>
import axios from 'axios';

export default {
  created() {
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('UAUTHTKKEY');
  
        if (!token) {
          this.$router.push('/')
        }
  
        config.headers.Authorization = `Bearer ${token}`;
  
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
    (response) => {
      return response;
    },
      async (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('UAUTHTKKEY');

          await router.push('/');

          return Promise.reject();
        }

        return Promise.reject(error);
      }
    );
  },
}
</script>

<template>
  <router-view/>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
  text-decoration: none;
  scroll-behavior: smooth;
  outline: none;
}

.form-divider {
  margin-top: 20px;
}
</style>
