<script>
import Input from '@/components/Input.vue'
import Head from './../../components/Head.vue'
import SectionTitle from './../../components/SectionTitle.vue'
import Button from '@/components/Button.vue'
import Section from '@/components/Section.vue'
import Table from './../../components/Table.vue'
import Select from '@/components/Select.vue'
import ReturnToPage from '@/components/ReturnToPage.vue'
import Loader from './../../components/Loader.vue'
import DeleteModal from './../../components/DeleteModal.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    props: [ 'userData' ],
    data() {
        return {
            loader: true,
            view: 'list-apprenticies',
            hasNextPage: undefined,
            itemsPerPage: 10,
            actualPage: 0,
            totalItems: 0,
            totalPages: 0,
            searchTermsFilter: '',
            tableHeaders: [
                'ID',
                'Nome Completo',
                'Escolaridade',
                'Cidade',
                'Status',
            ],
            tableActions: [
                'pencil-square',
                'trash',
            ],
            tableContents: [],
            tableContentsFullInfos: [],
            tableContentsFullInfosSelectedUser: [],
            editApprenticies: {
                name: '',
                email: '',
                date_of_birth: '',
                mother_name: '',
                father_name: '',
                gener: 'Masculino',
                marital_status: 'Solteiro',
                document_rg: '',
                document_cpf: '',
                phone_number: '',
                cellphone_number: '',
                work_card: '',
                series_work_card: '',
                registration_date: '',
                education: 'Ensino Medio Incompleto',
                has_course: 'Nao',
                course_name: '',
                school_name: '',
                shift_course: 'Manha',
                university_education: 'Nao',
                university_education_name: '',
                address_city: '',
                address: '',
                address_zipcode: '',
                has_enlist: 'Nao',
                has_army_reservist: 'Nao',
                army_reservist_number: '',
                has_informatics_knowledge: 'Nao',
                has_disability: 'Nao',
                working_day: 'Indefinido',
                work_days_select: 'Indefinido',
                work_schedule: '',
                total_hours_program: 0,
                program_total_contract: 'Indefinido',
                period_of_program: '',
                period_until_program: '',
                total_hours_phase_theoretical: 0,
                total_hours_theoretical: 0,
                total_week_hours_theoretical: 0,
                day_theoretical: 'Indefinido',
                hours_phase_theoretical: '',
                period_of_theoretical_phase: '',
                period_until_theoretical_phase: '',
            },
            generYoungApprenticeSelect: [
                {
                    name: 'Masculino',
                    value: 'Masculino',
                    selected: true
                },
                {
                    name: 'Feminino',
                    value: 'Feminino',
                    selected: false
                },
                {
                    name: 'Prefiro não dizer',
                    value: 'Indefinido',
                    selected: false
                },
            ],
            maritalStatusYoungApprenticeSelect: [
                {
                    name: 'Solteiro',
                    value: 'Solteiro',
                    selected: true
                },
                {
                    name: 'Casado',
                    value: 'Casado',
                    selected: false
                },
                {
                    name: 'Divorciado',
                    value: 'Divorciado',
                    selected: false
                },
                {
                    name: 'Viúvo',
                    value: 'Viuvo',
                    selected: false
                },
            ],
            educationYoungApprenticeSelect: [
                {
                    name: 'Ensino Médio Incompleto',
                    value: 'Ensino Medio Incompleto',
                    selected: true
                },
                {
                    name: 'Ensino Médio Completo',
                    value: 'Ensino Medio Completo',
                    selected: false
                },
            ],
            hasCourseYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            shiftCourseYoungApprenticeSelect: [
                {
                    name: 'Manhã',
                    value: 'Manha',
                    selected: true
                },
                {
                    name: 'Tarde',
                    value: 'Tarde',
                    selected: false
                },
                {
                    name: 'Noite',
                    value: 'Noite',
                    selected: false
                },
            ],
            universityEducationYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            enlistYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            armyReservistYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            hasInformaticsKnowledgeYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim, Básico',
                    value: 'Basico',
                    selected: false
                },
                {
                    name: 'Sim, Intermediário',
                    value: 'Intermediario',
                    selected: false
                },
                {
                    name: 'Sim, Avançado',
                    value: 'Avancado',
                    selected: false
                },
            ],
            hasDisabilityYoungApprenticeSelect: [
                {
                    name: 'Não',
                    value: 'Nao',
                    selected: true
                },
                {
                    name: 'Sim',
                    value: 'Sim',
                    selected: false
                },
            ],
            hasDisabilityStatusSelect: [
                {
                    name: 'Ativo',
                    value: '1',
                    selected: true
                },
                {
                    name: 'Inativo',
                    value: '0',
                    selected: false
                },
            ],
            workingDaySelect: [
                {
                    name: 'Indefinido',
                    value: '0',
                    selected: true
                },
                {
                    name: '4h (horas)',
                    value: '4',
                    selected: false
                },
                {
                    name: '5h (horas)',
                    value: '5',
                    selected: false
                },
                {
                    name: '6h (horas)',
                    value: '6',
                    selected: false
                },
                {
                    name: '7h (horas)',
                    value: '7',
                    selected: false
                },
                {
                    name: '8h (horas)',
                    value: '8',
                    selected: false
                },
            ],
            courseDayContractSelect: [
                {
                    name: 'Indefinido',
                    value: 'Indefinido',
                    selected: true
                },
                {
                    name: 'Segunda-feira',
                    value: 'Segunda',
                    selected: false
                },
                {
                    name: 'Terça-feira',
                    value: 'Terca',
                    selected: false
                },
                {
                    name: 'Quarta-feira',
                    value: 'Quarta',
                    selected: false
                },
                {
                    name: 'Quinta-feira',
                    value: 'Quinta',
                    selected: false
                },
                {
                    name: 'Sexta-feira',
                    value: 'Sexta',
                    selected: false
                },
            ],
            workDaysSelect: [
                {
                    name: 'Indefinido',
                    value: 'Indefinido',
                    selected: true
                },
                {
                    name: 'Segunda à Sexta',
                    value: 'segunda-sexta',
                    selected: false
                },
                {
                    name: 'Segunda à Sábado',
                    value: 'segunda-sabado',
                    selected: false
                },
            ],
            programTotalContract: [
                {
                    name: 'Indefinido',
                    value: 'Indefinido',
                    selected: true
                },
                {
                    name: 'Segunda à Sexta',
                    value: 'segunda-sexta',
                    selected: false
                },
                {
                    name: 'Segunda à Sábado',
                    value: 'segunda-sabado',
                    selected: false
                },
            ], 
            theoreticalPhaseDaysSelect: [
                {
                    name: 'Indefinido',
                    value: 'Indefinido',
                    selected: true
                },
                {
                    name: 'Segunda-feira',
                    value: 'Segunda',
                    selected: false
                },
                {
                    name: 'Terça-feira',
                    value: 'Terca',
                    selected: false
                },
                {
                    name: 'Quarta-feira',
                    value: 'Quarta',
                    selected: false
                },
                {
                    name: 'Quinta-feira',
                    value: 'Quinta',
                    selected: false
                },
                {
                    name: 'Sexta-feira',
                    value: 'Sexta',
                    selected: false
                },
            ],
            deleteApprenticeModal: false,
            selectedDeleteId: null,
        }
    },
    created() {
        this.getNextPageApprentices();
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        getNextPageApprentices() {
            this.loader = true;

            this.actualPage += 1;

            this.$axios.get(`/young_apprentices?items_per_page=${this.itemsPerPage}&page=${this.actualPage}&terms_filter=${this.searchTermsFilter}`)
            .then(res => {
                console.log(res);

                const data = res.data.data.data;

                this.tableContentsFullInfos = data;

                var arr = [];

                data.forEach(element => {
                    arr.push({
                        id: element.id,
                        complete_name: element.name,
                        education: element.young_apprentice_data.education,
                        city: element.young_apprentice_data.address_city,
                        status: element.is_active ? 'Ativo' : 'Inativo'
                    });
                });

                this.tableContents = arr;

                res.data.data.last_page > 1 ? this.hasNextPage = true : this.hasNextPage = false;

                this.totalItems = res.data.data.total;
                this.totalPages = res.data.data.last_page;

                this.loader = false;
            })
            .catch(err => {
                console.log(err);
            });
        },
        listApprenticesActions(event) {
            if (event.eventType == 'pencil-square') {
                for (let i = 0; i < this.tableContentsFullInfos.length; i++) {
                    if (this.tableContentsFullInfos[i].id === event.data.id) {
                        this.tableContentsFullInfosSelectedUser = this.tableContentsFullInfos[i];
                    }
                }

                this.editApprenticies = {
                    name: this.tableContentsFullInfosSelectedUser.name,
                    email: this.tableContentsFullInfosSelectedUser.email,
                    date_of_birth: this.tableContentsFullInfosSelectedUser.young_apprentice_data.date_of_birth,
                    mother_name: this.tableContentsFullInfosSelectedUser.young_apprentice_data.mother_name,
                    father_name: this.tableContentsFullInfosSelectedUser.young_apprentice_data.father_name,
                    gener: this.tableContentsFullInfosSelectedUser.young_apprentice_data.gener,
                    marital_status: this.tableContentsFullInfosSelectedUser.young_apprentice_data.marital_status,
                    document_rg: this.tableContentsFullInfosSelectedUser.young_apprentice_data.document_rg,
                    document_cpf: this.tableContentsFullInfosSelectedUser.young_apprentice_data.document_cpf,
                    phone_number: this.tableContentsFullInfosSelectedUser.young_apprentice_data.phone_number,
                    cellphone_number: this.tableContentsFullInfosSelectedUser.young_apprentice_data.cellphone_number,
                    work_card: this.tableContentsFullInfosSelectedUser.young_apprentice_data.work_card,
                    series_work_card: this.tableContentsFullInfosSelectedUser.young_apprentice_data.series_work_card,
                    registration_date: this.tableContentsFullInfosSelectedUser.young_apprentice_data.registration_date,
                    education: this.tableContentsFullInfosSelectedUser.young_apprentice_data.education,
                    has_course: this.tableContentsFullInfosSelectedUser.young_apprentice_data.has_course,
                    course_name: this.tableContentsFullInfosSelectedUser.young_apprentice_data.course_name,
                    school_name: this.tableContentsFullInfosSelectedUser.young_apprentice_data.school_name,
                    shift_course: this.tableContentsFullInfosSelectedUser.young_apprentice_data.shift_course,
                    university_education: this.tableContentsFullInfosSelectedUser.young_apprentice_data.university_education,
                    university_education_name: this.tableContentsFullInfosSelectedUser.young_apprentice_data.university_education_name,
                    address_city: this.tableContentsFullInfosSelectedUser.young_apprentice_data.address_city,
                    address: this.tableContentsFullInfosSelectedUser.young_apprentice_data.address,
                    address_zipcode: this.tableContentsFullInfosSelectedUser.young_apprentice_data.address_zipcode,
                    has_enlist: this.tableContentsFullInfosSelectedUser.young_apprentice_data.has_enlist,
                    has_army_reservist: this.tableContentsFullInfosSelectedUser.young_apprentice_data.has_army_reservist,
                    army_reservist_number: this.tableContentsFullInfosSelectedUser.young_apprentice_data.army_reservist_number,
                    has_informatics_knowledge: this.tableContentsFullInfosSelectedUser.young_apprentice_data.has_informatics_knowledge,
                    has_disability: this.tableContentsFullInfosSelectedUser.young_apprentice_data.has_disability,
                    is_active: this.tableContentsFullInfosSelectedUser.is_active,
                    working_day: this.tableContentsFullInfosSelectedUser.young_apprentice_data.working_day,
                    work_days_select: this.tableContentsFullInfosSelectedUser.young_apprentice_data.work_days_select,
                    work_schedule: this.tableContentsFullInfosSelectedUser.young_apprentice_data.work_schedule,
                    total_hours_program: this.tableContentsFullInfosSelectedUser.young_apprentice_data.total_hours_program,
                    program_total_contract: this.tableContentsFullInfosSelectedUser.young_apprentice_data.program_total_contract,
                    period_of_program: this.tableContentsFullInfosSelectedUser.young_apprentice_data.period_of_program,
                    period_until_program: this.tableContentsFullInfosSelectedUser.young_apprentice_data.period_until_program,
                    total_hours_phase_theoretical: this.tableContentsFullInfosSelectedUser.young_apprentice_data.total_hours_phase_theoretical,
                    total_hours_theoretical: this.tableContentsFullInfosSelectedUser.young_apprentice_data.total_hours_theoretical,
                    total_week_hours_theoretical: this.tableContentsFullInfosSelectedUser.young_apprentice_data.total_week_hours_theoretical,
                    day_theoretical: this.tableContentsFullInfosSelectedUser.young_apprentice_data.day_theoretical,
                    hours_phase_theoretical: this.tableContentsFullInfosSelectedUser.young_apprentice_data.hours_phase_theoretical,
                    period_of_theoretical_phase: this.tableContentsFullInfosSelectedUser.young_apprentice_data.period_of_theoretical_phase,
                    period_until_theoretical_phase: this.tableContentsFullInfosSelectedUser.young_apprentice_data.period_until_theoretical_phase,
                };

                this.hasDisabilityStatusSelect = [
                    {
                        name: 'Ativo',
                        value: '1',
                        selected: this.tableContentsFullInfosSelectedUser.is_active ? true : false
                    },
                    {
                        name: 'Inativo',
                        value: '0',
                        selected: !this.tableContentsFullInfosSelectedUser.is_active ? true : false
                    },
                ];

                this.generYoungApprenticeSelect = [
                    {
                        name: 'Masculino',
                        value: 'Masculino',
                        selected: this.editApprenticies.gener == 'Masculino' ? true : false,
                    },
                    {
                        name: 'Feminino',
                        value: 'Feminino',
                        selected: this.editApprenticies.gener == 'Feminino' ? true : false,
                    },
                    {
                        name: 'Prefiro não dizer',
                        value: 'Indefinido',
                        selected: this.editApprenticies.gener == 'Indefinido' ? true : false,
                    },
                ];

                this.maritalStatusYoungApprenticeSelect = [
                    {
                        name: 'Solteiro',
                        value: 'Solteiro',
                        selected: this.editApprenticies.marital_status == 'Solteiro' ? true : false,
                    },
                    {
                        name: 'Casado',
                        value: 'Casado',
                        selected: this.editApprenticies.marital_status == 'Casado' ? true : false,
                    },
                    {
                        name: 'Divorciado',
                        value: 'Divorciado',
                        selected: this.editApprenticies.marital_status == 'Divorciado' ? true : false,
                    },
                    {
                        name: 'Viúvo',
                        value: 'Viuvo',
                        selected: this.editApprenticies.marital_status == 'Viuvo' ? true : false,
                    },
                ];

                this.educationYoungApprenticeSelect = [
                    {
                        name: 'Ensino Médio Incompleto',
                        value: 'Ensino Medio Incompleto',
                        selected: this.editApprenticies.education == 'Ensino Medio Incompleto' ? true : false,
                    },
                    {
                        name: 'Ensino Médio Completo',
                        value: 'Ensino Medio Completo',
                        selected: this.editApprenticies.education == 'Ensino Medio Completo' ? true : false,
                    },
                ];

                this.hasCourseYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_course == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_course == 'Sim' ? true : false,
                    },
                ];

                this.shiftCourseYoungApprenticeSelect = [
                    {
                        name: 'Manhã',
                        value: 'Manha',
                        selected: this.editApprenticies.shift_course == 'Manha' ? true : false,
                    },
                    {
                        name: 'Tarde',
                        value: 'Tarde',
                        selected: this.editApprenticies.shift_course == 'Tarde' ? true : false,
                    },
                    {
                        name: 'Noite',
                        value: 'Noite',
                        selected: this.editApprenticies.shift_course == 'Noite' ? true : false,
                    },
                ];

                this.universityEducationYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.university_education == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.university_education == 'Sim' ? true : false,
                    },
                ];

                this.enlistYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_enlist == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_enlist == 'Sim' ? true : false,
                    },
                ];

                this.armyReservistYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_army_reservist == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_army_reservist == 'Sim' ? true : false,
                    },
                ];

                this.hasInformaticsKnowledgeYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim, Básico',
                        value: 'Basico',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Basico' ? true : false,
                    },
                    {
                        name: 'Sim, Intermediário',
                        value: 'Intermediario',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Intermediario' ? true : false,
                    },
                    {
                        name: 'Sim, Avançado',
                        value: 'Avancado',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Avancado' ? true : false,
                    },
                ];

                this.hasDisabilityYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_disability == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_disability == 'Sim' ? true : false,
                    },
                ];

                this.workingDaySelect = [
                    {
                        name: 'Indefinido',
                        value: '0',
                        selected: this.editApprenticies.working_day == '0' ? true : false,
                    },
                    {
                        name: '4h (horas)',
                        value: '4',
                        selected: this.editApprenticies.working_day == '4' ? true : false,
                    },
                    {
                        name: '5h (horas)',
                        value: '5',
                        selected: this.editApprenticies.working_day == '5' ? true : false,
                    },
                    {
                        name: '6h (horas)',
                        value: '6',
                        selected: this.editApprenticies.working_day == '6' ? true : false,
                    },
                    {
                        name: '7h (horas)',
                        value: '7',
                        selected: this.editApprenticies.working_day == '7' ? true : false,
                    },
                    {
                        name: '8h (horas)',
                        value: '8',
                        selected: this.editApprenticies.working_day == '8' ? true : false,
                    },
                ],

                this.workDaysSelect = [
                    {
                        name: 'Indefinido',
                        value: 'Indefinido',
                        selected: this.editApprenticies.work_days_select == 'Indefinido' ? true : false,
                    },
                    {
                        name: 'Segunda à Sexta',
                        value: 'segunda-sexta',
                        selected: this.editApprenticies.work_days_select == 'segunda-sexta' ? true : false,
                    },
                    {
                        name: 'Segunda à Sábado',
                        value: 'segunda-sabado',
                        selected: this.editApprenticies.work_days_select == 'segunda-sabado' ? true : false,
                    },
                ];

                this.programTotalContract = [
                    {
                        name: 'Indefinido',
                        value: 'Indefinido',
                        selected: this.editApprenticies.program_total_contract == 'Indefinido' ? true : false,
                    },
                    {
                        name: 'Segunda à Sexta',
                        value: 'segunda-sexta',
                        selected: this.editApprenticies.program_total_contract == 'segunda-sexta' ? true : false,
                    },
                    {
                        name: 'Segunda à Sábado',
                        value: 'segunda-sabado',
                        selected: this.editApprenticies.program_total_contract == 'segunda-sabado' ? true : false,
                    },
                ];

                this.theoreticalPhaseDaysSelect = [
                {
                    name: 'Indefinido',
                    value: 'Indefinido',
                    selected: this.editApprenticies.day_theoretical == 'Indefinido' ? true : false,
                },
                {
                    name: 'Segunda-feira',
                    value: 'Segunda',
                    selected: this.editApprenticies.day_theoretical == 'Segunda' ? true : false,
                },
                {
                    name: 'Terça-feira',
                    value: 'Terca',
                    selected: this.editApprenticies.day_theoretical == 'Terca' ? true : false,
                },
                {
                    name: 'Quarta-feira',
                    value: 'Quarta',
                    selected: this.editApprenticies.day_theoretical == 'Quarta' ? true : false,
                },
                {
                    name: 'Quinta-feira',
                    value: 'Quinta',
                    selected: this.editApprenticies.day_theoretical == 'Quinta' ? true : false,
                },
                {
                    name: 'Sexta-feira',
                    value: 'Sexta',
                    selected: this.editApprenticies.day_theoretical == 'Sexta' ? true : false,
                },
            ],

                this.view = 'edit-apprenticies';
            }

            if (event.eventType == 'trash') {
                for (let i = 0; i < this.tableContentsFullInfos.length; i++) {
                    if (this.tableContentsFullInfos[i].id === event.data.id) {
                        this.selectedDeleteId = this.tableContentsFullInfos[i].id;
                    }
                }

                this.deleteApprenticeModal = true;
            }
        },
        setStatusOnCreateNewApprentices() {
            this.hasDisabilityStatusSelect = [
                    {
                        name: 'Ativo',
                        value: '1',
                        selected: true
                    },
                    {
                        name: 'Inativo',
                        value: '0',
                        selected: false
                    },
                ];
        },
        actionPaginationArrows(event) {
            console.log(event);
            if (event == 'previous') {
                this.actualPage -= 2;

                this.getNextPageApprentices();
            };

            if (event == 'next') {
                this.getNextPageApprentices();
            };
        },
        actionPaginationPages(event) {
            this.actualPage = event - 1;

            this.getNextPageApprentices();
        },
        getApprenticesFilter() {
            this.actualPage = 0;

            this.getNextPageApprentices();
        },
        editYoungApprenticies() {
            if (this.view == 'add-apprenticies') {
                console.log(this.editApprenticies);
                if (
                    !this.editApprenticies.name ||
                    !this.editApprenticies.email ||
                    !this.editApprenticies.date_of_birth ||
                    !this.editApprenticies.mother_name ||
                    !this.editApprenticies.document_cpf ||
                    !this.editApprenticies.cellphone_number ||
                    !this.editApprenticies.work_card ||
                    !this.editApprenticies.series_work_card ||
                    !this.editApprenticies.registration_date ||
                    !this.editApprenticies.university_education ||
                    !this.editApprenticies.address_city ||
                    !this.editApprenticies.address ||
                    !this.editApprenticies.address_zipcode
                ) {
                    return this.notify('Preencha todos os campos para concluir o cadastro de aprendiz!', 'error');
                }

                this.editApprenticies.document_cpf = this.editApprenticies.document_cpf.replace(/\D/g, '');

                this.loader = true;

                this.$axios.post('young_apprentices', this.editApprenticies)
                .then(res => {
                    this.notify('Jovem aprendiz criado com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a criação do jovem aprendiz!', 'error');
                    this.loader = false;
                });
            }

            if (this.view == 'edit-apprenticies') {
                this.editApprenticies.document_cpf = this.editApprenticies.document_cpf.replace(/\D/g, '');

                this.loader = true;
                
                this.$axios.put(`young_apprentices/${this.tableContentsFullInfosSelectedUser.id}`, this.editApprenticies)
                .then(res => {
                    this.notify('Dados do jovem aprendiz foram atualizados com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a atualização de dados do usuário!', 'error');
                });
            }
        },
        returnToApprenticesPage() {
            this.editApprenticies = {
                name: '',
                email: '',
                date_of_birth: '',
                mother_name: '',
                father_name: '',
                gener: 'Masculino',
                marital_status: 'Solteiro',
                document_rg: '',
                document_cpf: '',
                phone_number: '',
                cellphone_number: '',
                work_card: '',
                series_work_card: '',
                registration_date: '',
                education: 'Ensino Medio Incompleto',
                has_course: 'Nao',
                course_name: '',
                school_name: '',
                shift_course: 'Manha',
                university_education: 'Nao',
                university_education_name: '',
                address_city: '',
                address: '',
                address_zipcode: '',
                has_enlist: 'Nao',
                has_army_reservist: 'Nao',
                army_reservist_number: '',
                has_informatics_knowledge: 'Nao',
                has_disability: 'Nao',
            };
            
            this.view = 'list-apprenticies';
        },
        modalDeleteClickedButton(event) {
            console.log(event);
            if (!event) {
                this.selectedDeleteId = null;
                this.deleteApprenticeModal = false;
            }

            if (event) {
                this.loader = true;

                this.$axios.delete(`/young_apprentices/${this.selectedDeleteId}`)
                .then(res => {
                    this.notify('jovem aprendiz removido com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                })
                .catch(err => {
                    this.notify('Ocorreu um erro e não foi possível remover esse jovem aprendiz!', 'error');
                });
            }
        },
    },
    components: { Head, SectionTitle, Input, Button, Section, Table, Select, ReturnToPage, Loader, DeleteModal }
}
</script>

<template>
    <Loader v-if="loader" />

    <DeleteModal v-if="deleteApprenticeModal" @clickButton="modalDeleteClickedButton" title="Deletar aprendiz" />

    <section v-if="!loader">
        <Head :title="view == 'list-apprenticies' ? 'Aprendizes' : view == 'edit-apprenticies' ? 'Editar aprendiz' : 'Adicionar aprendiz'" />

        <!-- Adicionar ou editar aprendiz -->
        <Section v-if="view == 'edit-apprenticies' || view == 'add-apprenticies'">
            <ReturnToPage placeholder="Voltar para a listagem de aprendrizes" redirectTo="" @clickReturn="returnToApprenticesPage"></ReturnToPage>

            <div class="form-divider"></div>

            <!-- Dados do aprendiz -->
            <SectionTitle :title="view == 'edit-apprenticies' ? `Dados do aprendiz - ${tableContentsFullInfosSelectedUser.name}` : 'Dados do aprendiz'" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Seu nome completo" label="Nome completo*" :value="editApprenticies.name" v-model="editApprenticies.name" />

            <div class="form-divider"></div>

            <Input type="email" placeholder="Seu melhor e-mail" label="E-mail*" :value="editApprenticies.email" v-model="editApprenticies.email" />

            <div class="form-divider"></div>

            <Input type="date" placeholder="dd/mm/yyy" label="Data de nascimento*" :value="editApprenticies.date_of_birth" v-model="editApprenticies.date_of_birth" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome completo da sua mãe" label="Nome da mãe*" :value="editApprenticies.mother_name" v-model="editApprenticies.mother_name" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome completo do seu pai" label="Nome do pai" :value="editApprenticies.father_name" v-model="editApprenticies.father_name" />

            <div class="form-divider"></div>

            <Select label="Sexo*" :options="generYoungApprenticeSelect" :value="editApprenticies.gener" v-model="editApprenticies.gener" />

            <div class="form-divider"></div>

            <Select label="Estado civil*" :options="maritalStatusYoungApprenticeSelect" :value="editApprenticies.marital_status" v-model="editApprenticies.marital_status" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Seu documento CPF" label="CPF*" :value="editApprenticies.document_cpf" v-model="editApprenticies.document_cpf" :onlyNumbers="true" :maxLength="14" :cpfMask="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número de contato do responsável" label="Contato do responsável" :value="editApprenticies.phone_number" v-model="editApprenticies.phone_number" :onlyNumbers="true" :maxLength="15" :phoneMask="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número de contato do aprendiz" label="Contato do aprendiz*" :value="editApprenticies.cellphone_number" v-model="editApprenticies.cellphone_number" :onlyNumbers="true" :maxLength="15" :phoneMask="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número da sua carteira de trabalho" label="Nº Carteira de trabalho*" :value="editApprenticies.work_card" v-model="editApprenticies.work_card" :onlyNumbers="true" :maxLength="100" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Série da sua carteira de trabalho" label="Nº Série Carteira de trabalho*" :value="editApprenticies.series_work_card" v-model="editApprenticies.series_work_card" :onlyNumbers="true" :maxLength="100" />

            <div class="form-divider"></div>

            <Input type="date" placeholder="dd/mm/yyy" label="Data de cadastro*" :value="editApprenticies.registration_date" v-model="editApprenticies.registration_date" />

            <div class="form-divider"></div>

            <!-- Escolaridade -->
            <SectionTitle title="Escolaridade" />

            <div class="form-divider"></div>

            <Select label="Escolaridade*" :options="educationYoungApprenticeSelect" :value="editApprenticies.education" v-model="editApprenticies.education" />

            <div class="form-divider"></div>

            <Select label="Fez algum curso?*" :options="hasCourseYoungApprenticeSelect" :value="editApprenticies.has_course" v-model="editApprenticies.has_course" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o nome do curso que você fez" label="Nome do curso" :value="editApprenticies.course_name" v-model="editApprenticies.course_name" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o nome da sua escola" label="Escola" :value="editApprenticies.school_name" v-model="editApprenticies.school_name" />

            <div class="form-divider"></div>

            <Select label="Turno disponível para trabalhar*" :options="shiftCourseYoungApprenticeSelect" :value="editApprenticies.shift_course" v-model="editApprenticies.shift_course" />

            <div class="form-divider"></div>

            <Select label="Ensino Superior*" :options="universityEducationYoungApprenticeSelect" :value="editApprenticies.university_education" v-model="editApprenticies.university_education" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o nome do ensino que você fez" label="Nome do ensino" :value="editApprenticies.university_education_name" v-model="editApprenticies.university_education_name" />

            <div class="form-divider"></div>

            <!-- Informações adicionais -->
            <SectionTitle title="Informações adicionais" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite sua cidade" label="Cidade*" :value="editApprenticies.address_city" v-model="editApprenticies.address_city" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite seu endereço" label="Endereço*" :value="editApprenticies.address" v-model="editApprenticies.address" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o CEP do seu endereço" label="CEP*" :value="editApprenticies.address_zipcode" v-model="editApprenticies.address_zipcode" :maxLength="12" :cepMask="true" />

            <div class="form-divider"></div>

            <Select label="Já se alistou no serviço militar?*" :options="enlistYoungApprenticeSelect" :value="editApprenticies.has_enlist" v-model="editApprenticies.has_enlist" />

            <div class="form-divider"></div>

            <Select label="Possui reservista?*" :options="armyReservistYoungApprenticeSelect" :value="editApprenticies.has_army_reservist" v-model="editApprenticies.has_army_reservist" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número da sua carteira de reservista" label="N° Cart. Reservista" :value="editApprenticies.army_reservist_number" v-model="editApprenticies.army_reservist_number" />

            <div class="form-divider"></div>

            <Select label="Tem conhecimento de informática?*" :options="hasInformaticsKnowledgeYoungApprenticeSelect" :value="editApprenticies.has_informatics_knowledge" v-model="editApprenticies.has_informatics_knowledge" />

            <div class="form-divider"></div>

            <Select label="Portador de necessidades especiais?*" :options="hasDisabilityYoungApprenticeSelect" :value="editApprenticies.has_disability" v-model="editApprenticies.has_disability" />

            <div class="form-divider"></div>

            <Select label="Status*" :options="hasDisabilityStatusSelect" :value="editApprenticies.is_active" v-model="editApprenticies.is_active" />

            <div class="form-divider"></div>

            <!-- Dados de contratos -->
            <SectionTitle title="Dados de contratos" />

            <div class="form-divider"></div>

            <Select label="Jornada" :options="workingDaySelect" :value="editApprenticies.working_day" v-model="editApprenticies.working_day" />

            <!-- <div class="form-divider"></div>

            <Select label="Dia de curso" :options="workingDaySelect" :value="editApprenticies.course_day_contract" v-model="editApprenticies.course_day_contract" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="287.00" label="Valor do curso" :value="editApprenticies.course_value_contract" v-model="editApprenticies.course_value_contract" /> -->

            <div class="form-divider"></div>

            <Input type="text" placeholder="6,45 (Seis reais e quarenta e cinco centavos)" label="Valor" :value="editApprenticies.total_hours_phase_theoretical" v-model="editApprenticies.total_hours_phase_theoretical" />

            <div class="form-divider"></div>

            <!-- <Input type="text" placeholder="4" label="Horas na aula teórica" :value="editApprenticies.total_hours_theoretical" v-model="editApprenticies.total_hours_theoretical" :onlyNumbers="true" />

            <div class="form-divider"></div> -->
            
            <!-- <Input type="text" placeholder="40" label="Horas semanais da fase teórica" :value="editApprenticies.total_week_hours_theoretical" v-model="editApprenticies.total_week_hours_theoretical" :onlyNumbers="true" />

            <div class="form-divider"></div> -->

            <Select label="Dia curso" :options="theoreticalPhaseDaysSelect" :value="editApprenticies.day_theoretical" v-model="editApprenticies.day_theoretical" />
            
            <div class="form-divider"></div>

            <Input type="text" placeholder="10:00 às 16:00" label="Horário do curso" :value="editApprenticies.hours_phase_theoretical" v-model="editApprenticies.hours_phase_theoretical" />

            <div class="form-divider"></div>

            <Input type="date" placeholder="" label="Inicio da fase teórica" :value="editApprenticies.period_of_theoretical_phase" v-model="editApprenticies.period_of_theoretical_phase" />

            <div class="form-divider"></div>

            <Input type="date" placeholder="" label="Fim da fase teórica" :value="editApprenticies.period_until_theoretical_phase" v-model="editApprenticies.period_until_theoretical_phase" />

            <div class="form-divider"></div>

            <Select label="Dias de trabalho" :options="workDaysSelect" :value="editApprenticies.work_days_select" v-model="editApprenticies.work_days_select" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="10:00 às 16:00" label="Horário de trabalho" :value="editApprenticies.work_schedule" v-model="editApprenticies.work_schedule" />

            <!-- <div class="form-divider"></div>

            <Input type="text" label="Horas diárias do programa" :value="editApprenticies.total_hours_program" v-model="editApprenticies.total_hours_program" :onlyNumbers="true" /> -->

            <div class="form-divider"></div>

            <Select label="Dias do programa" :options="programTotalContract" :value="editApprenticies.program_total_contract" v-model="editApprenticies.program_total_contract" />

            <div class="form-divider"></div>

            <!-- <Input type="date" placeholder="" label="Programa inicia em" :value="editApprenticies.period_of_theoretical_phase" v-model="editApprenticies.period_of_theoretical_phase" />

            <div class="form-divider"></div>

            <Input type="date" placeholder="" label="Programa termina em" :value="editApprenticies.period_until_theoretical_phase" v-model="editApprenticies.period_until_theoretical_phase" />

            <div class="form-divider"></div> -->

            <Button type="primary" :placeholder="view == 'edit-apprenticies' ? `Editar aprendiz` : 'Adicionar aprendiz'" @buttonPressed="editYoungApprenticies" />
        </Section>

        <!-- Listar aprendizes -->
        <Section v-if="view == 'list-apprenticies'">
            <SectionTitle title="Gerenciar aprendizes" />

            <div class="form-divider"></div>

            <section class="top-add-button">
                <Button @buttonPressed="setStatusOnCreateNewApprentices(); view = 'add-apprenticies'" class="add-button" type="primary" placeholder="+ Adicionar aprendiz" />
            </section>

            <div class="form-divider"></div>

            <Input :searchButton="true" placeholder="Nome, E-mail ou CPF" label="Buscar por termos" :value="searchTermsFilter" v-model="searchTermsFilter" @clickSearchButton="getApprenticesFilter" />

            <div class="form-divider"></div>

            <Table v-if="!loader" :hasActions="true" :actions="tableActions" :hasNextPage="hasNextPage" :totalItems="totalItems" :totalPages="totalPages" :actualPage="actualPage" :headers="tableHeaders" :contents="tableContents" @loadMore="getNextPage" @clickAction="listApprenticesActions" @paginationArrows="actionPaginationArrows" @paginationPages="actionPaginationPages" />
        </Section>
    </section>
</template>

<style scoped>
.top-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -55px;
}

.add-button {
    width: 300px;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .top-add-button {
        margin-top: 0px;
    }

    .add-button {
        width: 100%;
        justify-content: center;
    }
}
</style>