<script>
import Input from '@/components/Input.vue'
import Head from './../../components/Head.vue'
import SectionTitle from './../../components/SectionTitle.vue'
import Button from '@/components/Button.vue'
import Section from '@/components/Section.vue'
import Table from './../../components/Table.vue'
import Select from '@/components/Select.vue'
import ReturnToPage from '@/components/ReturnToPage.vue'
import Loader from './../../components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    props: [ 'userData' ],
    data() {
        return {
            loader: true,
            editApprenticies: {
                name: '',
                email: '',
                date_of_birth: '',
                mother_name: '',
                father_name: '',
                gener: 'Masculino',
                marital_status: 'Solteiro',
                document_rg: '',
                document_cpf: '',
                phone_number: '',
                cellphone_number: '',
                work_card: '',
                series_work_card: '',
                registration_date: '',
                education: 'Ensino Medio Incompleto',
                has_course: 'Nao',
                course_name: '',
                school_name: '',
                shift_course: 'Manha',
                university_education: 'Nao',
                university_education_name: '',
                address_city: '',
                address: '',
                address_zipcode: '',
                has_enlist: 'Nao',
                has_army_reservist: 'Nao',
                army_reservist_number: '',
                has_informatics_knowledge: 'Nao',
                has_disability: 'Nao',
            },
            editCompany: {
                name_legal_representative: '',
                name_youth_supervisor: '',
                phone_youth_supervisor: '',
                email_youth_supervisor: '',
                fantasy_name_company: '',
                social_reason_company: '',
                cnpj_company: '',
                code_company: '',
                state_city_company: '',
                address_company: '',
                address_zipcode_company: '',
                phone_company: '',
            }
        }
    },
    created() {
        if (this.userData.role == 'youngapprentice') {
            this.$axios.get('/user')
            .then(res => {
                this.editApprenticies = {
                    name: res.data.name,
                    email: res.data.email,
                    date_of_birth: res.data.young_apprentice_data.date_of_birth,
                    mother_name: res.data.young_apprentice_data.mother_name,
                    father_name: res.data.young_apprentice_data.father_name,
                    gener: res.data.young_apprentice_data.gener,
                    marital_status: res.data.young_apprentice_data.marital_status,
                    document_rg: res.data.young_apprentice_data.document_rg,
                    document_cpf: res.data.young_apprentice_data.document_cpf,
                    phone_number: res.data.young_apprentice_data.phone_number,
                    cellphone_number: res.data.young_apprentice_data.cellphone_number,
                    work_card: res.data.young_apprentice_data.work_card,
                    series_work_card: res.data.young_apprentice_data.series_work_card,
                    registration_date: res.data.young_apprentice_data.registration_date,
                    education: res.data.young_apprentice_data.education,
                    has_course: res.data.young_apprentice_data.has_course,
                    course_name: res.data.young_apprentice_data.course_name,
                    school_name: res.data.young_apprentice_data.school_name,
                    shift_course: res.data.young_apprentice_data.shift_course,
                    university_education: res.data.young_apprentice_data.university_education,
                    university_education_name: res.data.young_apprentice_data.university_education_name,
                    address_city: res.data.young_apprentice_data.address_city,
                    address: res.data.young_apprentice_data.address,
                    address_zipcode: res.data.young_apprentice_data.address_zipcode,
                    has_enlist: res.data.young_apprentice_data.has_enlist,
                    has_army_reservist: res.data.young_apprentice_data.has_army_reservist,
                    army_reservist_number: res.data.young_apprentice_data.army_reservist_number,
                    has_informatics_knowledge: res.data.young_apprentice_data.has_informatics_knowledge,
                    has_disability: res.data.young_apprentice_data.has_disability
                };

                this.hasDisabilityStatusSelect = [
                    {
                        name: 'Ativo',
                        value: '1',
                        selected: res.data.is_active ? true : false
                    },
                    {
                        name: 'Inativo',
                        value: '0',
                        selected: !res.data.is_active ? true : false
                    },
                ];

                this.generYoungApprenticeSelect = [
                    {
                        name: 'Masculino',
                        value: 'Masculino',
                        selected: this.editApprenticies.gener == 'Masculino' ? true : false,
                    },
                    {
                        name: 'Feminino',
                        value: 'Feminino',
                        selected: this.editApprenticies.gener == 'Feminino' ? true : false,
                    },
                    {
                        name: 'Prefiro não dizer',
                        value: 'Indefinido',
                        selected: this.editApprenticies.gener == 'Indefinido' ? true : false,
                    },
                ];

                this.maritalStatusYoungApprenticeSelect = [
                    {
                        name: 'Solteiro',
                        value: 'Solteiro',
                        selected: this.editApprenticies.marital_status == 'Solteiro' ? true : false,
                    },
                    {
                        name: 'Casado',
                        value: 'Casado',
                        selected: this.editApprenticies.marital_status == 'Casado' ? true : false,
                    },
                    {
                        name: 'Divorciado',
                        value: 'Divorciado',
                        selected: this.editApprenticies.marital_status == 'Divorciado' ? true : false,
                    },
                    {
                        name: 'Viúvo',
                        value: 'Viuvo',
                        selected: this.editApprenticies.marital_status == 'Viuvo' ? true : false,
                    },
                ];

                this.educationYoungApprenticeSelect = [
                    {
                        name: 'Ensino Médio Incompleto',
                        value: 'Ensino Medio Incompleto',
                        selected: this.editApprenticies.education == 'Ensino Medio Incompleto' ? true : false,
                    },
                    {
                        name: 'Ensino Médio Completo',
                        value: 'Ensino Medio Completo',
                        selected: this.editApprenticies.education == 'Ensino Medio Completo' ? true : false,
                    },
                ];

                this.hasCourseYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_course == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_course == 'Sim' ? true : false,
                    },
                ];

                this.shiftCourseYoungApprenticeSelect = [
                    {
                        name: 'Manhã',
                        value: 'Manha',
                        selected: this.editApprenticies.shift_course == 'Manha' ? true : false,
                    },
                    {
                        name: 'Tarde',
                        value: 'Tarde',
                        selected: this.editApprenticies.shift_course == 'Tarde' ? true : false,
                    },
                    {
                        name: 'Noite',
                        value: 'Noite',
                        selected: this.editApprenticies.shift_course == 'Noite' ? true : false,
                    },
                ];

                this.universityEducationYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.university_education == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.university_education == 'Sim' ? true : false,
                    },
                ];

                this.enlistYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_enlist == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_enlist == 'Sim' ? true : false,
                    },
                ];

                this.armyReservistYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_army_reservist == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_army_reservist == 'Sim' ? true : false,
                    },
                ];

                this.hasInformaticsKnowledgeYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim, Básico',
                        value: 'Basico',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Basico' ? true : false,
                    },
                    {
                        name: 'Sim, Intermediário',
                        value: 'Intermediario',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Intermediario' ? true : false,
                    },
                    {
                        name: 'Sim, Avançado',
                        value: 'Avancado',
                        selected: this.editApprenticies.has_informatics_knowledge == 'Avancado' ? true : false,
                    },
                ];

                this.hasDisabilityYoungApprenticeSelect = [
                    {
                        name: 'Não',
                        value: 'Nao',
                        selected: this.editApprenticies.has_disability == 'Nao' ? true : false,
                    },
                    {
                        name: 'Sim',
                        value: 'Sim',
                        selected: this.editApprenticies.has_disability == 'Sim' ? true : false,
                    },
                ];

                this.loader = false;
            })
            .catch(err => {
                this.notify('Ocorreu um erro ao tentar buscar os seus dados!', 'error');
            });
        }

        if (this.userData.role == 'company') {
            this.$axios.get('/user')
            .then(res => {
                this.editCompany = {
                    name_legal_representative: res.data.company_data.name_legal_representative,
                    name_youth_supervisor: res.data.company_data.name_youth_supervisor,
                    phone_youth_supervisor: res.data.company_data.phone_youth_supervisor,
                    email_youth_supervisor: res.data.company_data.email_youth_supervisor,
                    fantasy_name_company: res.data.company_data.fantasy_name_company,
                    social_reason_company: res.data.company_data.social_reason_company,
                    cnpj_company: res.data.company_data.cnpj_company,
                    code_company: res.data.company_data.code_company,
                    state_city_company: res.data.company_data.state_city_company,
                    address_company: res.data.company_data.address_company,
                    address_zipcode_company: res.data.company_data.address_zipcode_company,
                    phone_company: res.data.company_data.phone_company,
                    is_active: res.data.is_active
                };

                this.loader = false;
            })
            .catch(err => {
                this.notify('Ocorreu um erro ao tentar buscar os seus dados!', 'error');
            });
        }
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        updateUserData() {
            this.loader = true;

            this.$axios.put(`young_apprentices/${this.userData.id}`, this.editApprenticies)
            .then(res => {
                this.notify('Dados do jovem aprendiz foram atualizados com sucesso!', 'success');

                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            })
            .catch(err => {
                this.notify('Ocorreu um erro durante a atualização de dados do usuário!', 'error');

                this.loader = false;
            });;
        },
        updateUserDataCompany() {
            this.loader = true;

            this.$axios.put(`companies/${this.userData.id}`, this.editCompany)
            .then(res => {
                this.notify('Dados do jovem aprendiz foram atualizados com sucesso!', 'success');

                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            })
            .catch(err => {
                this.notify('Ocorreu um erro durante a atualização de dados do usuário!', 'error');

                this.loader = false;
            });;
        },
    },
    components: { Head, SectionTitle, Input, Button, Section, Table, Select, ReturnToPage, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section v-if="!loader && userData.role == 'youngapprentice'">
        <Head title="Meu perfil" />

        <!-- Tela para aprendizes -->
        <Section>
            <SectionTitle title="Edite seus dados" />

            <div class="form-divider"></div>

            <!-- Dados do aprendiz -->
            <Input type="text" placeholder="Seu nome completo" label="Nome completo*" :value="editApprenticies.name" v-model="editApprenticies.name" />

            <div class="form-divider"></div>

            <Input type="email" placeholder="Seu e-mail" label="E-mail*" :value="editApprenticies.email" :disabled="true" />

            <div class="form-divider"></div>

            <Input type="date" placeholder="dd/mm/yyy" label="Data de nascimento*" :value="editApprenticies.date_of_birth" :disabled="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome completo da sua mãe" label="Nome da mãe*" :value="editApprenticies.mother_name" v-model="editApprenticies.mother_name" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome completo do seu pai" label="Nome do pai" :value="editApprenticies.father_name" v-model="editApprenticies.father_name" />

            <div class="form-divider"></div>

            <Select label="Sexo*" :options="generYoungApprenticeSelect" :value="editApprenticies.gener" v-model="editApprenticies.gener" />

            <div class="form-divider"></div>

            <Select label="Estado civil*" :options="maritalStatusYoungApprenticeSelect" :value="editApprenticies.marital_status" v-model="editApprenticies.marital_status" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Seu documento CPF" label="CPF*" :value="editApprenticies.document_cpf" :disabled="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número de contato do responsável" label="Contato do responsável" :value="editApprenticies.phone_number" v-model="editApprenticies.phone_number" :onlyNumbers="true" :maxLength="13" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número de contato do aprendiz" label="Contato do aprendiz*" :value="editApprenticies.cellphone_number" v-model="editApprenticies.cellphone_number" :onlyNumbers="true" :maxLength="13" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número da sua carteira de trabalho" label="Nº Carteira de trabalho*" :value="editApprenticies.work_card" v-model="editApprenticies.work_card" :onlyNumbers="true" :maxLength="100" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Série da sua carteira de trabalho" label="Nº Série Carteira de trabalho*" :value="editApprenticies.series_work_card" v-model="editApprenticies.series_work_card" :onlyNumbers="true" :maxLength="100" />

            <div class="form-divider"></div>

            <Input type="date" placeholder="dd/mm/yyy" label="Data de cadastro*" :value="editApprenticies.registration_date" v-model="editApprenticies.registration_date" />

            <div class="form-divider"></div>

            <!-- Escolaridade -->
            <SectionTitle title="Escolaridade" />

            <div class="form-divider"></div>

            <Select label="Escolaridade*" :options="educationYoungApprenticeSelect" :value="editApprenticies.education" v-model="editApprenticies.education" />

            <div class="form-divider"></div>

            <Select label="Fez algum curso?*" :options="hasCourseYoungApprenticeSelect" :value="editApprenticies.has_course" v-model="editApprenticies.has_course" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o nome do curso que você fez" label="Nome do curso" :value="editApprenticies.course_name" v-model="editApprenticies.course_name" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o nome da sua escola" label="Escola" :value="editApprenticies.school_name" v-model="editApprenticies.school_name" />

            <div class="form-divider"></div>

            <Select label="Turno disponível para trabalhar*" :options="shiftCourseYoungApprenticeSelect" :value="editApprenticies.shift_course" v-model="editApprenticies.shift_course" />

            <div class="form-divider"></div>

            <Select label="Ensino Superior*" :options="universityEducationYoungApprenticeSelect" :value="editApprenticies.university_education" v-model="editApprenticies.university_education" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o nome do ensino que você fez" label="Nome do ensino" :value="editApprenticies.university_education_name" v-model="editApprenticies.university_education_name" />

            <div class="form-divider"></div>

            <!-- Informações adicionais -->
            <SectionTitle title="Informações adicionais" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite sua cidade" label="Cidade*" :value="editApprenticies.address_city" v-model="editApprenticies.address_city" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite seu endereço" label="Endereço*" :value="editApprenticies.address" v-model="editApprenticies.address" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o CEP do seu endereço" label="CEP*" :value="editApprenticies.address_zipcode" v-model="editApprenticies.address_zipcode" />

            <div class="form-divider"></div>

            <Select label="Já se alistou no serviço militar?*" :options="enlistYoungApprenticeSelect" :value="editApprenticies.has_enlist" v-model="editApprenticies.has_enlist" />

            <div class="form-divider"></div>

            <Select label="Possui reservista?*" :options="armyReservistYoungApprenticeSelect" :value="editApprenticies.has_army_reservist" v-model="editApprenticies.has_army_reservist" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Número da sua carteira de reservista" label="N° Cart. Reservista" :value="editApprenticies.army_reservist_number" v-model="editApprenticies.army_reservist_number" />

            <div class="form-divider"></div>

            <Select label="Tem conhecimento de informática?*" :options="hasInformaticsKnowledgeYoungApprenticeSelect" :value="editApprenticies.has_informatics_knowledge" v-model="editApprenticies.has_informatics_knowledge" />

            <div class="form-divider"></div>

            <Select label="Portador de necessidades especiais?*" :options="hasDisabilityYoungApprenticeSelect" :value="editApprenticies.has_disability" v-model="editApprenticies.has_disability" />

            <div class="form-divider"></div>

            <Button type="primary" placeholder="Atualizar meus dados" @buttonPressed="updateUserData" />
        </Section>
    </section>

    <!-- Tela para empresas -->
    <section v-if="!loader && userData.role == 'company'">
        <Head title="Meu perfil" />

        <Section>
            <SectionTitle title="Edite seus dados" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome do representante legal" label="Representante legal*" :value="editCompany.name_legal_representative" v-model="editCompany.name_legal_representative" />

            <div class="form-divider"></div>

            <Input type="email" placeholder="E-mail de acesso" label="E-mail de supervisor*" :value="editCompany.email_youth_supervisor" v-model="editCompany.email_youth_supervisor" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome do supervisor de aprendiz" label="Supervisor de aprendiz*" :value="editCompany.name_youth_supervisor" v-model="editCompany.name_youth_supervisor" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Telefone do supervisor de aprendiz" label="Telefone de supervisor" :value="editCompany.phone_youth_supervisor" v-model="editCompany.phone_youth_supervisor" :onlyNumbers="true" :maxLength="13" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome fantasia da sua empresa*" label="Nome fantasia" :value="editCompany.fantasy_name_company" v-model="editCompany.fantasy_name_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Razão social da sua empresa*" label="Razão social" :value="editCompany.social_reason_company" v-model="editCompany.social_reason_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="CNPJ da sua empresa*" label="CNPJ" :value="editCompany.cnpj_company" v-model="editCompany.cnpj_company" :onlyNumbers="true" :maxLength="14" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Código de atividade da sua empresa*" label="Código de atividade" :value="editCompany.code_company" v-model="editCompany.code_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Estado e cidade da sua empresa" label="Cidade/Estado*" :value="editCompany.state_city_company" v-model="editCompany.state_city_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Endereço da sua empresa" label="Endereço*" :value="editCompany.address_company" v-model="editCompany.address_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="CEP da sua empresa" label="CEP*" :value="editCompany.address_zipcode_company" v-model="editCompany.address_zipcode_company" :onlyNumbers="true" :max="12" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Contato da sua empresa" label="Telefone*" :value="editCompany.phone_company" v-model="editCompany.phone_company" :onlyNumbers="true" :maxLength="13" />

            <div class="form-divider"></div>

            <Button type="primary" placeholder="Atualizar meus dados" @buttonPressed="updateUserDataCompany" />
        </Section>
    </section>
</template>

<style scoped>
.top-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -55px;
}

.add-button {
    width: 300px;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .top-add-button {
        margin-top: 0px;
    }

    .add-button {
        width: 100%;
        justify-content: center;
    }
}
</style>