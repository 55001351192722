<script>
export default {
    props: {
        placeholder: {
            type: String,
            required: true
        },
        redirectTo: {
            type: String,
            required: true
        }
    },
    methods: {
        emitClick() {
            this.$emit('clickReturn');
        }
    }
}
</script>

<template>
    <section class="bg-return-to-page">
        <router-link :to="redirectTo" class="router-link" @click="emitClick">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18" />
            </svg>

            {{ placeholder }}
        </router-link>
    </section>
</template>

<style scoped>
.bg-return-to-page {
    width: 100%;
}

.bg-return-to-page .router-link {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #E73E19;
}

.bg-return-to-page .router-link:hover {
    color: #f35a38;
}

.bg-return-to-page .router-link .icon {
    width: 16px;
    margin-right: 5px;
    color: #E73E19;
}

.bg-return-to-page .router-link:hover .icon {
    color: #f35a38;
}
</style>