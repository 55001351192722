<template>
    <section class="bg-section">
        <slot></slot>
    </section>
</template>

<style scoped>
.bg-section {
    width: 100%;
    height: auto;
    padding: 30px;
    background-color: white;
    border: 1px solid rgb(234, 234, 234);
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px 15px;
}
</style>