<script>
import Input from '@/components/Input.vue'
import Head from './../../components/Head.vue'
import SectionTitle from './../../components/SectionTitle.vue'
import Button from '@/components/Button.vue'
import Section from '@/components/Section.vue'
import Table from './../../components/Table.vue'
import Select from '@/components/Select.vue'
import ReturnToPage from '@/components/ReturnToPage.vue'
import Loader from './../../components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    props: [ 'userData' ],
    data() {
        return {
            loader: true,
            view: 'list-cbos',
            hasNextPage: undefined,
            itemsPerPage: 10,
            actualPage: 0,
            totalItems: 0,
            totalPages: 0,
            searchTermsFilter: '',
            tableHeaders: [
                'ID',
                'Código',
                'Descrição',
            ],
            tableActions: [
                'pencil-square'
            ],
            tableContents: [],
            tableContentsFullInfos: [],
            tableContentsFullInfosSelectedUser: [],
            editCBO: {
                code: '',
                description: ''
            },
            statusCompaniesSelect: [],
        }
    },
    created() {
        this.getNextPageApprentices();
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        getNextPageApprentices() {
            this.loader = true;

            this.actualPage += 1;

            this.$axios.get(`/cbos?items_per_page=${this.itemsPerPage}&page=${this.actualPage}&terms_filter=${this.searchTermsFilter}`)
            .then(res => {
                console.log(res);

                const data = res.data.data.data;

                this.tableContentsFullInfos = data;

                var arr = [];

                data.forEach(element => {
                    arr.push({
                        id: element.id,
                        code: element.code,
                        description: element.description
                    });
                });

                this.tableContents = arr;

                res.data.data.last_page > 1 ? this.hasNextPage = true : this.hasNextPage = false;

                this.totalItems = res.data.data.total;
                this.totalPages = res.data.data.last_page;

                this.loader = false;
            })
            .catch(err => {
                console.log(err);
            });
        },
        listApprenticesActions(event) {
            if (event.eventType == 'pencil-square') {
                for (let i = 0; i < this.tableContentsFullInfos.length; i++) {
                    if (this.tableContentsFullInfos[i].id === event.data.id) {
                        this.tableContentsFullInfosSelectedUser = this.tableContentsFullInfos[i];
                    }
                }

                this.editCBO = {
                    code: this.tableContentsFullInfosSelectedUser.code,
                    description: this.tableContentsFullInfosSelectedUser.description,
                };

                this.view = 'edit-cbos';
            }
        },
        actionPaginationArrows(event) {
            console.log(event);
            if (event == 'previous') {
                this.actualPage -= 2;

                this.getNextPageApprentices();
            };

            if (event == 'next') {
                this.getNextPageApprentices();
            };
        },
        actionPaginationPages(event) {
            this.actualPage = event - 1;

            this.getNextPageApprentices();
        },
        getApprenticesFilter() {
            this.actualPage = 0;

            this.getNextPageApprentices();
        },
        editYoungApprenticies() {
            if (this.view == 'add-cbos') {
                console.log(this.editCBO);
                if (!this.editCBO.code) {
                    return this.notify('Preencha o campo de código e função!', 'error');
                }

                this.loader = true;

                this.$axios.post('cbos', this.editCBO)
                .then(res => {
                    this.notify('CBO criado com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a criação da empresa!', 'error');
                    this.loader = false;
                });
            }

            if (this.view == 'edit-cbos') {
                this.loader = true;
                
                this.$axios.put(`cbos/${this.tableContentsFullInfosSelectedUser.id}`, this.editCBO)
                .then(res => {
                    this.notify('Dados da empresa atualizados com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a atualização de dados da empresa!', 'error');
                });
            }
        }
    },
    components: { Head, SectionTitle, Input, Button, Section, Table, Select, ReturnToPage, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section v-if="!loader">
        <Head :title="view == 'list-cbos' ? 'CBOS' : view == 'edit-cbos' ? 'Editar CBOS' : 'Adicionar CBOS'" />

        <!-- Adicionar ou editar CBO -->
        <Section v-if="view == 'edit-cbos' || view == 'add-cbos'">
            <ReturnToPage placeholder="Voltar para a listagem de cbos" redirectTo="" @clickReturn="view = 'list-cbos'"></ReturnToPage>

            <div class="form-divider"></div>

            <Input type="text" placeholder="Digite o código e função" label="Código - Função" :value="editCBO.code" v-model="editCBO.code" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Adicione uma descrição sobre suas funções nesse cargo" label="Descrição" :value="editCBO.description" v-model="editCBO.description" />

            <div class="form-divider"></div>

            <Button type="primary" :placeholder="view == 'edit-cbos' ? `Editar CBO` : 'Adicionar CBO'" @buttonPressed="editYoungApprenticies" />
        </Section>

        <!-- Listar CBOS -->
        <Section v-if="view == 'list-cbos'">
            <SectionTitle title="Gerenciar CBOS" />

            <div class="form-divider"></div>

            <section class="top-add-button">
                <Button @buttonPressed="view = 'add-cbos'" class="add-button" type="primary" placeholder="+ Adicionar CBO" />
            </section>

            <div class="form-divider"></div>

            <Input :searchButton="true" placeholder="Código" label="Filtre por código" :value="searchTermsFilter" v-model="searchTermsFilter" @clickSearchButton="getApprenticesFilter" />

            <div class="form-divider"></div>

            <Table v-if="!loader" :hasActions="true" :actions="tableActions" :hasNextPage="hasNextPage" :totalItems="totalItems" :totalPages="totalPages" :actualPage="actualPage" :headers="tableHeaders" :contents="tableContents" @loadMore="getNextPage" @clickAction="listApprenticesActions" @paginationArrows="actionPaginationArrows" @paginationPages="actionPaginationPages" />
        </Section>
    </section>
</template>

<style scoped>
.top-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -55px;
}

.add-button {
    width: 300px;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .top-add-button {
        margin-top: 0px;
    }

    .add-button {
        width: 100%;
        justify-content: center;
    }
}
</style>