<script>
import Input from '@/components/Input.vue'
import Head from './../../components/Head.vue'
import SectionTitle from './../../components/SectionTitle.vue'
import Button from '@/components/Button.vue'
import Section from '@/components/Section.vue'
import Table from './../../components/Table.vue'
import Select from '@/components/Select.vue'
import ReturnToPage from '@/components/ReturnToPage.vue'
import Loader from './../../components/Loader.vue'
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
    props: [ 'userData' ],
    data() {
        return {
            loader: true,
            view: 'list-companies',
            hasNextPage: undefined,
            itemsPerPage: 10,
            actualPage: 0,
            totalItems: 0,
            totalPages: 0,
            searchTermsFilter: '',
            tableHeaders: [
                'ID',
                'Razão Social',
                'Cidade/Estado',
                'CNPJ',
                'Status',
            ],
            tableActions: [
                'pencil-square'
            ],
            tableContents: [],
            tableContentsFullInfos: [],
            tableContentsFullInfosSelectedUser: [],
            editCompany: {
                name_legal_representative: '',
                name_youth_supervisor: '',
                phone_youth_supervisor: '',
                email_youth_supervisor: '',
                fantasy_name_company: '',
                social_reason_company: '',
                cnpj_company: '',
                code_company: '',
                state_city_company: '',
                address_company: '',
                address_zipcode_company: '',
                phone_company: '',
                is_active: ''
            },
            statusCompaniesSelect: [],
        }
    },
    created() {
        this.getNextPageApprentices();
    },
    methods: {
        notify(text, type) {
            toast(text, {
                "type": type == 'info' ? 'info' : type == 'warning' ? 'warning' : type == 'error' ? 'error' : type == 'success' ? 'success' : 'default',
            });
        },
        getNextPageApprentices() {
            this.loader = true;

            this.actualPage += 1;

            this.$axios.get(`/companies?items_per_page=${this.itemsPerPage}&page=${this.actualPage}&terms_filter=${this.searchTermsFilter}`)
            .then(res => {
                console.log(res);

                const data = res.data.data.data;

                this.tableContentsFullInfos = data;

                var arr = [];

                data.forEach(element => {
                    arr.push({
                        id: element.id,
                        complete_name: element.company_data.social_reason_company,
                        state_city_company: element.company_data.state_city_company,
                        cnpj_company: element.company_data.cnpj_company,
                        status: element.is_active ? 'Ativa' : 'Inativa'
                    });
                });

                this.tableContents = arr;

                res.data.data.last_page > 1 ? this.hasNextPage = true : this.hasNextPage = false;

                this.totalItems = res.data.data.total;
                this.totalPages = res.data.data.last_page;

                this.loader = false;
            })
            .catch(err => {
                console.log(err);
            });
        },
        listApprenticesActions(event) {
            if (event.eventType == 'pencil-square') {
                for (let i = 0; i < this.tableContentsFullInfos.length; i++) {
                    if (this.tableContentsFullInfos[i].id === event.data.id) {
                        this.tableContentsFullInfosSelectedUser = this.tableContentsFullInfos[i];
                    }
                }

                this.editCompany = {
                    name_legal_representative: this.tableContentsFullInfosSelectedUser.company_data.name_legal_representative,
                    name_youth_supervisor: this.tableContentsFullInfosSelectedUser.company_data.name_youth_supervisor,
                    phone_youth_supervisor: this.tableContentsFullInfosSelectedUser.company_data.phone_youth_supervisor,
                    email_youth_supervisor: this.tableContentsFullInfosSelectedUser.company_data.email_youth_supervisor,
                    fantasy_name_company: this.tableContentsFullInfosSelectedUser.company_data.fantasy_name_company,
                    social_reason_company: this.tableContentsFullInfosSelectedUser.company_data.social_reason_company,
                    cnpj_company: this.tableContentsFullInfosSelectedUser.company_data.cnpj_company,
                    code_company: this.tableContentsFullInfosSelectedUser.company_data.code_company,
                    state_city_company: this.tableContentsFullInfosSelectedUser.company_data.state_city_company,
                    address_company: this.tableContentsFullInfosSelectedUser.company_data.address_company,
                    address_zipcode_company: this.tableContentsFullInfosSelectedUser.company_data.address_zipcode_company,
                    phone_company: this.tableContentsFullInfosSelectedUser.company_data.phone_company,
                    is_active: this.tableContentsFullInfosSelectedUser.is_active
                };

                this.statusCompaniesSelect = [
                    {
                        name: 'Ativa',
                        value: 1,
                        selected: this.tableContentsFullInfosSelectedUser.is_active == 'Nao' ? true : false,
                    },
                    {
                        name: 'Inativa',
                        value: 0,
                        selected: this.tableContentsFullInfosSelectedUser.is_active == 'Sim' ? true : false,
                    }
                ];

                this.view = 'edit-companies';
            }
        },
        actionPaginationArrows(event) {
            console.log(event);
            if (event == 'previous') {
                this.actualPage -= 2;

                this.getNextPageApprentices();
            };

            if (event == 'next') {
                this.getNextPageApprentices();
            };
        },
        actionPaginationPages(event) {
            this.actualPage = event - 1;

            this.getNextPageApprentices();
        },
        getApprenticesFilter() {
            this.actualPage = 0;

            this.getNextPageApprentices();
        },
        editYoungApprenticies() {
            if (this.view == 'add-companies') {
                console.log(this.editCompany);
                if (
                    !this.editCompany.name_legal_representative,
                    !this.editCompany.name_youth_supervisor,
                    !this.editCompany.email_youth_supervisor,
                    !this.editCompany.fantasy_name_company,
                    !this.editCompany.social_reason_company,
                    !this.editCompany.cnpj_company,
                    !this.editCompany.code_company,
                    !this.editCompany.state_city_company,
                    !this.editCompany.address_company,
                    !this.editCompany.address_zipcode_company,
                    !this.editCompany.phone_company
                ) {
                    return this.notify('Preencha todos os campos para concluir o cadastro da empresa!', 'error');
                }

                this.editCompany.cnpj_company = this.editCompany.cnpj_company.replace(/\D/g, '');

                this.loader = true;

                this.$axios.post('companies', this.editCompany)
                .then(res => {
                    this.notify('Empresa criada com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a criação da empresa!', 'error');
                    this.loader = false;
                });
            }

            if (this.view == 'edit-companies') {
                this.editCompany.cnpj_company = this.editCompany.cnpj_company.replace(/\D/g, '');

                this.loader = true;
                
                this.$axios.put(`companies/${this.tableContentsFullInfosSelectedUser.id}`, this.editCompany)
                .then(res => {
                    this.notify('Dados da empresa atualizados com sucesso!', 'success');

                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                })
                .catch(err => {
                    this.notify('Ocorreu um erro durante a atualização de dados da empresa!', 'error');
                });
            }
        }
    },
    components: { Head, SectionTitle, Input, Button, Section, Table, Select, ReturnToPage, Loader }
}
</script>

<template>
    <Loader v-if="loader" />

    <section v-if="!loader">
        <Head :title="view == 'list-companies' ? 'Empresas' : view == 'edit-companies' ? 'Editar empresa' : 'Adicionar empresa'" />

        <!-- Adicionar ou editar empresa -->
        <Section v-if="view == 'edit-companies' || view == 'add-companies'">
            <ReturnToPage placeholder="Voltar para a listagem de empresas" redirectTo="" @clickReturn="view = 'list-companies'"></ReturnToPage>

            <div class="form-divider"></div>

            <Input type="email" placeholder="E-mail de acesso" label="E-mail de supervisor*" :value="editCompany.email_youth_supervisor" v-model="editCompany.email_youth_supervisor" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome do representante legal" label="Representante legal*" :value="editCompany.name_legal_representative" v-model="editCompany.name_legal_representative" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome do supervisor de aprendiz" label="Supervisor de aprendiz*" :value="editCompany.name_youth_supervisor" v-model="editCompany.name_youth_supervisor" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Telefone do supervisor de aprendiz" label="Telefone de supervisor" :value="editCompany.phone_youth_supervisor" v-model="editCompany.phone_youth_supervisor" :onlyNumbers="true" :maxLength="15" :phoneMask="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Nome fantasia da sua empresa*" label="Nome fantasia" :value="editCompany.fantasy_name_company" v-model="editCompany.fantasy_name_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Razão social da sua empresa*" label="Razão social" :value="editCompany.social_reason_company" v-model="editCompany.social_reason_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="CNPJ da sua empresa*" label="CNPJ" :value="editCompany.cnpj_company" v-model="editCompany.cnpj_company" :onlyNumbers="true" :maxLength="18" :cnpjMask="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Código de atividade da sua empresa*" label="Código de atividade" :value="editCompany.code_company" v-model="editCompany.code_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Estado e cidade da sua empresa" label="Cidade/Estado*" :value="editCompany.state_city_company" v-model="editCompany.state_city_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Endereço da sua empresa" label="Endereço*" :value="editCompany.address_company" v-model="editCompany.address_company" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="CEP da sua empresa" label="CEP*" :value="editCompany.address_zipcode_company" v-model="editCompany.address_zipcode_company" :onlyNumbers="true" :maxLength="12" :cepMask="true" />

            <div class="form-divider"></div>

            <Input type="text" placeholder="Contato da sua empresa" label="Telefone*" :value="editCompany.phone_company" v-model="editCompany.phone_company" :onlyNumbers="true" :maxLength="15" :phoneMask="true" />

            <div class="form-divider"></div>

            <Select v-if="view == 'edit-companies'" label="Status*" :options="statusCompaniesSelect" :value="editCompany.is_active" v-model="editCompany.is_active" />

            <div class="form-divider"></div>

            <Button type="primary" :placeholder="view == 'edit-companies' ? `Editar empresa` : 'Adicionar empresa'" @buttonPressed="editYoungApprenticies" />
        </Section>

        <!-- Listar empresas -->
        <Section v-if="view == 'list-companies'">
            <SectionTitle title="Gerenciar empresas" />

            <div class="form-divider"></div>

            <section class="top-add-button">
                <Button @buttonPressed="view = 'add-companies'" class="add-button" type="primary" placeholder="+ Adicionar empresa" />
            </section>

            <div class="form-divider"></div>

            <Input :searchButton="true" placeholder="Razão Social, E-mail ou CPF" label="Buscar por termos" :value="searchTermsFilter" v-model="searchTermsFilter" @clickSearchButton="getApprenticesFilter" />

            <div class="form-divider"></div>

            <Table v-if="!loader" :hasActions="true" :actions="tableActions" :hasNextPage="hasNextPage" :totalItems="totalItems" :totalPages="totalPages" :actualPage="actualPage" :headers="tableHeaders" :contents="tableContents" @loadMore="getNextPage" @clickAction="listApprenticesActions" @paginationArrows="actionPaginationArrows" @paginationPages="actionPaginationPages" />
        </Section>
    </section>
</template>

<style scoped>
.top-add-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: -55px;
}

.add-button {
    width: 300px;
    max-width: 100%;
}

@media screen and (max-width: 700px) {
    .top-add-button {
        margin-top: 0px;
    }

    .add-button {
        width: 100%;
        justify-content: center;
    }
}
</style>